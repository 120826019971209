import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { API_URL } from "../../config";
import { useAuth } from "../../AuthContext";

const useGetKeys = () => {
	const [data, setData] = useState([]); // Инициализация пустым массивом
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const { logout } = useAuth();

	// Функция для загрузки данных
	const fetchKeys = useCallback(async () => {
		setLoading(true); // Устанавливаем состояние загрузки
		try {
			const token = localStorage.getItem("token"); // Извлечение токена из локального хранилища

			const response = await axios.get(
				`${API_URL}/keys_management/get_keys.php`,
				{
					headers: {
						Authorization: `Bearer ${token}`, // Добавление токена в заголовок
					},
				}
			);

			// Прямо устанавливаем полученные данные в состояние
			setData(response.data); // response.data уже является массивом
		} catch (err) {
			if (err.response?.status === 401) {
				logout();
				return false;
			}
			setError(err.message);
		} finally {
			setLoading(false); // Останавливаем состояние загрузки
		}
	}, [logout]);

	// Эффект для загрузки данных при монтировании компонента
	useEffect(() => {
		fetchKeys();
	}, [fetchKeys]); // Функция загрузки вызывается только один раз при монтировании

	// Метод для ручного обновления данных
	const refetch = () => {
		fetchKeys(); // Просто вызываем fetchKeys для перезагрузки данных
	};

	return [data, setData, error, loading, refetch]; // Возвращаем refetch вместе с остальными значениями
};

export default useGetKeys;
