import { useContext, useEffect } from "react";

import { Typography } from "@mui/material";
import { PageTitleContext } from "../../PageTitleContext";
import TableUsers from "./TableUsers";

function UsersVerwalten() {
	// Изменить название заголовка страницы
	const { setTitle } = useContext(PageTitleContext);
	useEffect(() => {
		setTitle("Mitarbeiter verwalten");
	}, [setTitle]);

	return (
		<div className="deleteObjekt">
			<div className="deleteObjekt__content">
				<Typography
					variant="h3"
					sx={{ marginTop: "30px", marginBottom: "50px" }}
				>
					Hier können Sie Mitarbeiter verwalten.
				</Typography>
				<TableUsers />
			</div>
		</div>
	);
}

export default UsersVerwalten;
