import React from "react";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	CircularProgress,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import getColor from "../../hooks/useGetColor";

function DialogModal({
	open,
	handleClose,
	loading,
	activeObject,
	deleteObjekts,
}) {
	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>
				Sind Sie siche, dass Sie diese Objekten löschen wollen?
			</DialogTitle>
			<DialogContent>
				<div className="deleteObjekt__body-modal">
					{loading ? (
						<div className="scheduling__content-err">
							<CircularProgress color="secondary" />
						</div>
					) : (
						activeObject.map(item => {
							const streetStyle = {
								backgroundColor: getColor(item.city).backgroundColor,
								color: getColor(item.city).color,
							};

							return (
								<div
									key={item.id}
									data-id={item.id}
									style={streetStyle}
									className={`deleteObjekt__item _modal`} // Применяем класс _active
								>
									{item.address}
								</div>
							);
						})
					)}
				</div>
			</DialogContent>
			<DialogActions sx={{ paddingBottom: "20px", paddingRight: "20px" }}>
				<Button onClick={handleClose} variant="primary">
					Abbrechen
				</Button>
				<Button
					onClick={deleteObjekts}
					color="error"
					startIcon={<DeleteIcon />}
				>
					Löschen
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default DialogModal;
