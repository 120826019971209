const sortDataByCity = data => {
	// Сначала сортируем по городу
	const sortedByCity = [...data].sort((a, b) => a.city.localeCompare(b.city));

	// Затем сортируем по адресу внутри каждого города
	const sortedData = sortedByCity.sort((a, b) => {
		if (a.city === b.city) {
			// Сортируем по адресу, если города совпадают
			return a.address.localeCompare(b.address);
		}
		// Иначе сохраняем порядок по городу
		return 0;
	});

	return sortedData;
};

export default sortDataByCity;
