import { combineReducers } from "redux";
import {
	assignedAddressesReducer,
	selectedDayReducer,
	selectedWeekReducer,
} from "./addresses";

// Корневой редьюсер, объединяющий все остальные редьюсеры
const rootReducer = combineReducers({
	assignedAddresses: assignedAddressesReducer,
	selectedWeek: selectedWeekReducer,
	selectedDay: selectedDayReducer,
});

export default rootReducer;
