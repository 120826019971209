import { useState } from "react";
import { API_URL } from "../../config"; // Импортируем API_URL для сервера
import { useAuth } from "../../AuthContext";

const useAddUser = () => {
	const [loading, setLoading] = useState(false); // Состояние загрузки
	const [error, setError] = useState(null); // Состояние ошибки

	const { logout } = useAuth();

	const addUser = async userData => {
		setLoading(true); // Начинаем загрузку
		const token = localStorage.getItem("token"); // Извлекаем токен из localStorage

		if (!token) {
			setError("Token nicht gefunden. Bitte melden Sie sich an.");
			setLoading(false);
			return;
		}

		try {
			// Отправляем данные пользователя на сервер
			const response = await fetch(`${API_URL}/users/add_user.php`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`, // JWT-токен в заголовке
					// не устанавливаем Content-Type, браузер сделает это автоматически
				},
				body: userData, // Данные пользователя, включая файлы
			});

			const data = await response.json(); // Разбираем ответ
			console.dir(response);

			if (response.status === 401) {
				logout();
			}

			if (response.ok) {
				return data; // Возвращаем результат (например, ID нового пользователя)
			}

			if (data.error) {
				setError(data.error);
				throw new Error(data.error); // Если ошибка в ответе от сервера
			}
		} catch (err) {
			setError(err.message); // Ошибка запроса
			throw new Error(err.message); // Если ошибка в ответе от сервера
		} finally {
			setLoading(false); // Завершаем загрузку
		}
	};

	return { addUser, loading, error };
};

export default useAddUser;
