import capitalizeFirstLetter from "./capitalizeFirstLetter";
import convertFBWeek from "./convertFBWeek";
import convertTeam from "./translate/translateTeam";
import translateWeekday from "./translate/translateWeekday";

const groupByTeam = data => {
	// Сортируем данные по числовому значению из поля 'team'
	const sortedData = data.sort((a, b) => {
		const numA = parseInt(a.team.replace(/\D/g, ""), 10);
		const numB = parseInt(b.team.replace(/\D/g, ""), 10);
		return numA - numB;
	});

	// Группируем отсортированные данные по полю 'team'
	return sortedData.reduce((acc, item) => {
		if (!acc[item.team]) {
			acc[item.team] = [];
		}
		acc[item.team].push(item);
		return acc;
	}, {});
};

const createTableHTML = (team, data) => {
	const teamDE = convertTeam(team);

	let tableHTML = `
	  <h2>${teamDE}</h2>
	  <table border="1" style="margin-bottom: 20px;">
		<thead>
			<tr>
              <th style="width: 50%;">Adresse</th>
              <th>Gebiet</th>
              <th>Schlüßelhaken</th>
              <th>Rhytmus</th>
            </tr>
		</thead>
		<tbody>
	`;

	data.forEach(item => {
		tableHTML += `
		   <tr>
                <td style="width: 50%;">${item.address || "-"}</td>
                <td>${capitalizeFirstLetter(item.city || "-")}</td>
                <td>${item.keyObjekte || "-"}</td>
                <td>${item.rhythm || "-"}</td>
			</tr>
	  `;
	});

	tableHTML += `
		</tbody>
	  </table>
	`;

	return tableHTML;
};

const generateNormalPDF = (data, team, weekFB, weekday) => {
	const week = convertFBWeek(weekFB);
	const wochenTag = translateWeekday(weekday);
	const teamDE = convertTeam(team);

	// Создаем HTML-контент таблицы с данными
	const htmlContent = `
    <html>
      <head>
        <style>
          * {
              font-family: 'Source Sans Pro', sans-serif; /* Добавляем шрифт */
          }
          table {
            width: 100%;
            border-collapse: collapse;
          }
          th, td {
            border: 1px solid black;
            padding: 6px;
            text-align: left;
          }
        </style>
      </head>
      <body>
        <h2>Woche ${week}, ${wochenTag}<h2/>
        <h3>${teamDE}<h3/>
        <table>
          <thead>
            <tr>
              <th>Adresse</th>
              <th>Gebiet</th>
              <th>Schlüßelhaken</th>
              <th>Rhytmus</th>
            </tr>
          </thead>
          <tbody>
            ${data
							.map(
								item => `
              <tr>
                <td>${item.address || "-"}</td>
                <td>${capitalizeFirstLetter(item.city || "-")}</td>
                <td>${item.keyObjekte || "-"}</td>
                <td>${item.rhythm || "-"}</td>
              </tr>
            `
							)
							.join("")}
          </tbody>
        </table>
      </body>
    </html>
  `;
	return htmlContent;
};

const generateAllTeamPDF = (data, team, weekFB, weekday) => {
	const week = convertFBWeek(weekFB);
	const wochenTag = translateWeekday(weekday);
	const teamDE = convertTeam(team);
	const groupedData = groupByTeam(data);

	let resultHTML = "";

	Object.keys(groupedData).forEach(team => {
		resultHTML += createTableHTML(team, groupedData[team]);
	});

	// Создаем HTML-контент таблицы с данными
	const htmlContent = `
    <html>
      <head>
        <style>
          * {
              font-family: 'Source Sans Pro', sans-serif; /* Добавляем шрифт */
          }
          table {
            width: 100%;
            border-collapse: collapse;
          }
          th, td {
            border: 1px solid black;
            padding: 6px;
            text-align: left;
          }
        </style>
      </head>
      <body>
        <h2>Woche ${week}, ${wochenTag}<h2/>
        <h3>${teamDE}<h3/>
		${resultHTML}
      </body>
    </html>
  `;
	return htmlContent;
};

const generatePlanPDF = (data, team, weekFB, weekday) => {
	// Если никакой адрес не выбран
	if (!data.length) {
		return false;
	}

	let htmlContent;
	if (team !== "Alle Touren") {
		htmlContent = generateNormalPDF(data, team, weekFB, weekday);
	} else {
		htmlContent = generateAllTeamPDF(data, team, weekFB, weekday);
	}

	// Если есть объекты для расспечатывания
	if (htmlContent) {
		// Создаем новое окно браузера с созданным HTML-контентом
		const newWindow = window.open("", "_blank");
		newWindow.document.open();
		newWindow.document.write(htmlContent);
		newWindow.document.close();

		// Ожидаем, пока контент загрузится, затем преобразуем его в PDF
		newWindow.onload = () => {
			newWindow.print();
		};
	}
};

export default generatePlanPDF;
