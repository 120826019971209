import React, { useState, useEffect } from "react";
import {
	Modal,
	Paper,
	TextField,
	IconButton,
	Button,
	Typography,
	FormControl,
	Select,
	MenuItem,
	InputLabel,
	Box,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";
import { de } from "date-fns/locale"; // Немецкая локаль
import StatusIndicator from "../../components/UI/StatusIndicator";

const EditKeyModal = ({ open, keyData, onClose, onSave }) => {
	const [key, setKey] = useState(keyData || {});
	const [selectedTakenDate, setSelectedTakenDate] = useState(null);
	const [selectedReturnedDate, setSelectedReturnedDate] = useState(null);
	const [errors, setErrors] = useState({});  // Состояние для ошибок валидации

	useEffect(() => {
		if (keyData) {
			setKey(keyData);
			setSelectedTakenDate(keyData?.taken_date ? new Date(keyData.taken_date) : null);
			setSelectedReturnedDate(keyData?.returned_date ? new Date(keyData.returned_date) : null);
		}
	}, [keyData]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setKey({ ...key, [name]: value });
	};

	const handleTakenDateChange = (newDate) => {
		setSelectedTakenDate(newDate);
		setKey({
			...key,
			taken_date: newDate ? format(newDate, "yyyy-MM-dd HH:mm") : "",
		});
	};

	const handleReturnedDateChange = (newDate) => {
		setSelectedReturnedDate(newDate);
		setKey({
			...key,
			returned_date: newDate ? format(newDate, "yyyy-MM-dd HH:mm") : "",
		});
	};

	// Функция для проверки ошибок
	const validateFields = () => {
		const newErrors = {};
		if (!key?.key_address) newErrors.key_address = "Addresse ist erforderlich"; // Изменено на немецкий
		if (!key?.owner && !isReturnedStatus) newErrors.owner = "Genommen von ist erforderlich"; // Изменено на немецкий
		if (!key?.previous_owner && !isTakenStatus) newErrors.previous_owner = "Abgegeben von ist erforderlich"; // Изменено на немецкий
		if (!selectedTakenDate && !isReturnedStatus) newErrors.taken_date = "Genommen am ist erforderlich"; // Изменено на немецкий
		if (!selectedReturnedDate && !isTakenStatus) newErrors.returned_date = "Abgegeben am ist erforderlich"; // Изменено на немецкий

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleSave = () => {
		if (validateFields()) {
			onClose();
			onSave(key);
		}
	};

	// Определяем, заблокированы ли поля для "Genommen" или "Abgegeben"
	const isTakenStatus = key?.status === 0;  // если статус "Genommen"
	const isReturnedStatus = key?.status === 1;  // если статус "Abgegeben"

	return (
		<Modal open={open} onClose={onClose}>
			<Paper
				style={{
					padding: "40px",
					margin: "auto",
					maxWidth: 500,
					marginTop: "10%",
					borderRadius: "10px",
				}}
			>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Typography variant="h5" mb="30px">
						Schlüssel <span style={{ fontWeight: "bold" }}>{key?.key_address}</span> bearbeiten
					</Typography>
					<IconButton onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</Box>
				<Box display="flex" flexDirection="column" gap="20px">
					{/* Поле для адреса */}
					<TextField
						label="Addresse"
						fullWidth
						margin="normal"
						name="key_address"
						value={key?.key_address || ""}
						onChange={handleChange}
						error={Boolean(errors.key_address)}
						helperText={errors.key_address}
					/>

					{/* Поле для статуса */}
					<FormControl fullWidth sx={{ marginBottom: "15px" }}>
						<InputLabel>Status</InputLabel>
						<Select
							value={key?.status ?? ""}
							onChange={(e) => setKey({ ...key, status: e.target.value })}
							name="status"
						>
							<MenuItem value={0}>
								<Box display="flex" alignItems="center" gap="10px">
									<StatusIndicator status={0} date={false} />
									<span>Genommen</span>
								</Box>
							</MenuItem>
							<MenuItem value={1}>
								<Box display="flex" alignItems="center" gap="10px">
									<StatusIndicator status={1} date={false} />
									<span>Abgegeben</span>
								</Box>
							</MenuItem>
						</Select>
					</FormControl>

					{/* Календарь для даты взятия */}
					<LocalizationProvider dateAdapter={AdapterDateFns} locale={de}>
						<Box display="flex" alignItems="center" gap="10px" width="100%">
							<Box flexGrow={1} sx={{ "& .MuiFormControl-root": { width: "100%" } }}>
								<DateTimePicker
									label="Genommen am"
									value={selectedTakenDate}
									onChange={handleTakenDateChange}
									renderInput={(params) =>
										<TextField
											{...params}
											fullWidth
											disabled={isReturnedStatus}
											error={Boolean(errors.taken_date)} // Добавление ошибки
											helperText={errors.taken_date} // Показ ошибки
										/>
									}
									disabled={isReturnedStatus}
									inputFormat="dd/MM/yyyy HH:mm"
									disableSeconds
									ampm={false}
								/>
							</Box>
							<IconButton disabled={isReturnedStatus} onClick={() => handleTakenDateChange(null)} aria-label="Clear date">
								<CloseIcon />
							</IconButton>
						</Box>
					</LocalizationProvider>

					{/* Поле для взятия ключа */}
					<TextField
						label="Genommen von"
						fullWidth
						margin="normal"
						name="owner"
						value={key?.owner || ""}
						onChange={handleChange}
						sx={{ marginBottom: "15px", marginTop: 0 }}
						disabled={isReturnedStatus}
						error={Boolean(errors.owner)}
						helperText={errors.owner}
					/>

					{/* Календарь для даты возврата */}
					<LocalizationProvider dateAdapter={AdapterDateFns} locale={de}>
						<Box display="flex" alignItems="center" gap="10px" width="100%">
							<Box flexGrow={1} sx={{ "& .MuiFormControl-root": { width: "100%" } }}>
								<DateTimePicker
									label="Abgegeben am"
									value={selectedReturnedDate}
									onChange={handleReturnedDateChange}
									renderInput={(params) => (
										<TextField
											{...params}
											fullWidth
											disabled={isTakenStatus}
											error={Boolean(errors.returned_date)} // Добавление ошибки
											helperText={errors.returned_date} // Показ ошибки
										/>
									)}
									disabled={isTakenStatus}
									inputFormat="dd/MM/yyyy HH:mm"
									disableSeconds
									ampm={false}
								/>
							</Box>
							<IconButton disabled={isTakenStatus} onClick={() => handleReturnedDateChange(null)} aria-label="Clear date">
								<CloseIcon />
							</IconButton>
						</Box>
					</LocalizationProvider>

					{/* Поле для возврата ключа */}
					<TextField
						label="Abgegeben von"
						fullWidth
						margin="normal"
						name="previous_owner"
						value={key?.previous_owner || ""}
						onChange={handleChange}
						sx={{ margin: 0 }}
						disabled={isTakenStatus}
						error={Boolean(errors.previous_owner)}
						helperText={errors.previous_owner}
					/>
				</Box>

				{/* Кнопка для сохранения изменений */}
				<Button
					variant="contained"
					color="success"
					onClick={handleSave}
					style={{ marginTop: "20px", width: "100%" }}
				>
					Speichern
				</Button>
			</Paper>
		</Modal>
	);
};

export default EditKeyModal;
