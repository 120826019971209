import React, { useState } from "react";
import {
	Grid,
	Card,
	CardContent,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	TextField,
	Box
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import StatusIndicator from "../../components/UI/StatusIndicator"; // Если хотите оставить компонент для отображения статуса

// Стилизованный аккордеон без фона
const CustomAccordion = styled(Accordion)(({ theme }) => ({
	backgroundColor: "transparent",
	boxShadow: "none",
	width: '100%',
	"&:before": {
		display: "none", // Убирает линию перед аккордеоном
	},
}));

const KeyList = ({ title, keys, defaultExpanded = false }) => {
	// Состояние для фильтрации
	const [searchTerm, setSearchTerm] = useState("");

	// Фильтр списка по адресу ключа
	const filteredKeys = keys.filter(key =>
		key.key_address.toLowerCase().includes(searchTerm.toLowerCase())
	);

	return (
		<CustomAccordion defaultExpanded={defaultExpanded} sx={{ mb: 3 }}>
			{/* Заголовок со стрелкой */}
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls={`${title}-content`}
				id={`${title}-header`}
			>
				<Typography variant="h5" gutterBottom sx={{ mt: 0, mb: 0 }}>
					{title}
				</Typography>
			</AccordionSummary>

			{/* Контент, который раскрывается */}
			<AccordionDetails>
				{/* Поле для поиска */}
				<Box
					mb={3}
					sx={{
						width: { xs: "100%", sm: "400px" }, // На телефонах 100%, на ПК 400px
						marginX: "auto", // Центровка на ПК
					}}
				>
					<TextField
						fullWidth
						variant="outlined"
						placeholder="Addresse suchen"
						value={searchTerm}
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
				</Box>

				{/* Фильтрованный список */}
				<Grid container spacing={2}>
					{filteredKeys.map(key => (
						<Grid item xs={12} sm={6} md={4} key={key.id}>
							<Card>
								<CardContent>
									{/* Адрес ключа */}
									<Box display='flex' gap="20px" alignItems="center" mb='20px'>
										<Typography variant="h5">{key.key_address}</Typography>
										<StatusIndicator
											status={+key.status}
											takenDate={key.taken_date}
											returnedDate={key.returned_date}
											date={false}
										/>
									</Box>

									{/* Отображаем даты и действия */}
									<Typography variant="body2" color="textSecondary">
										{key.taken_date ? `Genommen: ${new Date(key.taken_date).toLocaleString()}` : 'Abgegeben: kein Datum'}
										{/* Кто взял ключ */}
										{key.taken_by && (
											<Typography variant="body2" color="textSecondary">
												{` von ${key.taken_by}`}
											</Typography>
										)}
									</Typography>

									{/* Проверка на статус для отображения даты возврата */}
									{+key.status === 1 && (
										<Typography variant="body2" color="textSecondary" mt={1}>
											{key.returned_date ? `Abgegeben: ${new Date(key.returned_date).toLocaleString()}` : "Abgegeben: noch nicht"}
										</Typography>
									)}

									{/* Кто вернул ключ */}
									{key.returned_by && (
										<Typography variant="body2" color="textSecondary">
											{` von ${key.returned_by}`}
										</Typography>
									)}
								</CardContent>
							</Card>
						</Grid>
					))}
				</Grid>

				{/* Сообщение, если ничего не найдено */}
				{filteredKeys.length === 0 && (
					<Typography variant="h5" align="center" mt={3}>
						Keine Adressen gefunden.
					</Typography>
				)}
			</AccordionDetails>
		</CustomAccordion>
	);
};

export default KeyList;
