import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../AuthContext";
import { API_URL } from "../../config";

const useDeleteKey = () => {
	const [isLoaded, setIsLoaded] = useState(null);
	const [error, setError] = useState(null);
	const { logout } = useAuth();

	const deleteKey = async id => {
		setIsLoaded(false);
		const token = localStorage.getItem("token");

		try {
			const response = await axios.delete(
				`${API_URL}/keys_management/delete_key.php?id=${id}`,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);

			setIsLoaded(true);
			return response.data;
		} catch (error) {
			if (error.response.status === 401) {
				logout();
			}
			console.log(error);
			setError(error.response?.data.error || "Fehler");
			setIsLoaded(true);
			throw error.response.data.error;
		}
	};

	return [deleteKey, isLoaded, error];
};

export default useDeleteKey;
