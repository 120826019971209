const translateWeekday = englishDay => {
	const daysOfWeek = {
		Monday: "Montag",
		Tuesday: "Dienstag",
		Wednesday: "Mittwoch",
		Thursday: "Donnerstag",
		Friday: "Freitag",
		Saturday: "Samstag",
		Sunday: "Sonntag",
	};

	return daysOfWeek[englishDay] || null; // Возвращаем соответствующий немецкий день недели или null, если день недели не найден
};

export default translateWeekday;
