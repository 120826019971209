import {
	ADD_ADDRESS,
	REMOVE_ADDRESS,
	CHANGE_WEEK,
	CHANGE_DAY,
} from "../actions/addresses";

// Редьюсер для назначенных адресов
export const assignedAddressesReducer = (state = {}, action) => {
	switch (action.type) {
		case ADD_ADDRESS:
			const { address, week, day } = action.payload;
			return {
				...state,
				[week]: {
					...state[week],
					[day]: [...(state[week]?.[day] || []), address],
				},
			};
		case REMOVE_ADDRESS:
			const { addressIdToRemove, weekToRemove, dayToRemove } = action.payload;
			return {
				...state,
				[weekToRemove]: {
					...state[weekToRemove],
					[dayToRemove]: state[weekToRemove][dayToRemove].filter(
						address => address.id !== addressIdToRemove
					),
				},
			};
		default:
			return state;
	}
};

// Редьюсер для выбранной недели
export const selectedWeekReducer = (state = "Week 1", action) => {
	switch (action.type) {
		case CHANGE_WEEK:
			return action.payload;
		default:
			return state;
	}
};

// Редьюсер для выбранного дня недели
export const selectedDayReducer = (state = "Monday", action) => {
	switch (action.type) {
		case CHANGE_DAY:
			return action.payload;
		default:
			return state;
	}
};
