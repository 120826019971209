import React from "react";
import { Button, Dialog, DialogTitle, DialogActions } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";

// Компонент модального окна подтверждения удаления
const DeleteUserModal = ({ open, onClose, onDelete }) => (
	<Dialog open={open} onClose={onClose}>
		<DialogTitle>
			Sind Sie sicher, dass Sie den Benutzer löschen wollen?
		</DialogTitle>
		<DialogActions>
			<Button onClick={onClose}>Abbrechen</Button>
			<Button onClick={onDelete} color="error" startIcon={<DeleteIcon />}>
				Löschen
			</Button>
		</DialogActions>
	</Dialog>
);

export default DeleteUserModal;
