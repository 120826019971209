import { API_URL } from "../config";

const checkWeek = async () => {
	try {
		await fetch(`${API_URL}/check_week.php`); // Замените на ваш URL API
	} catch (error) {
		console.error("Fehler check week API:", error);
	}
};
export default checkWeek;
