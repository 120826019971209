import React, { createContext, useContext, useState, useEffect } from "react";
import { API_URL } from "./config";
import decodeJWT from "./utils/decodeJWT";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [loadingLogin, setLoadingLogin] = useState(true);
	const [user, setUser] = useState(null); // Состояние для данных о пользователе

	// Проверяем состояние авторизации при монтировании компонента
	useEffect(() => {
		const token = localStorage.getItem("token");
		if (token) {
			checkSession(token); // Проверяем сессию, если токен есть
		} else {
			setLoadingLogin(false); // Если токена нет, просто заканчиваем загрузку
		}
	}, []);

	// Функция для проверки сессии
	const checkSession = async token => {
		try {
			const response = await fetch(`${API_URL}/users/check_session.php`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`, // Убедитесь, что этот токен правильный
				},
			});
			const data = await response.json();

			if (data.status === "success") {
				setIsAuthenticated(true);

				const decodedToken = decodeJWT(localStorage.getItem("token"));
				setUser(decodedToken.data); // Сохраняем данные пользователя, включая роль
			} else {
				setUser(null); // Обнуляем данные о пользователе
				localStorage.removeItem("token"); // Удаляем токен из локального хранилища
			}
		} catch (error) {
			console.error("Ошибка при проверке сессии:", error);
		} finally {
			setLoadingLogin(false); // Устанавливаем состояние загрузки в false
		}
	};

	// Метод для логина
	const login = async (username, password) => {
		try {
			const response = await fetch(`${API_URL}/users/login.php`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ username, password }),
			});

			const data = await response.json();

			if (data.status === "success") {
				setIsAuthenticated(true);
				// Декодируем токен и устанавливаем данные о пользователе
				const decodedToken = decodeJWT(data.token);
				setUser(decodedToken.data); // Используем decodedToken для получения данных о пользователе
				// Сохраняем только токен в локальном хранилище
				localStorage.setItem("token", data.token);
			} else {
				console.error(data.message); // Можно обработать сообщение об ошибке

				throw data;
			}
		} catch (error) {
			console.error("Ошибка при выполнении запроса на сервер:", error);
			throw error;
		}
	};

	// Метод для логаута
	const logout = () => {
		setIsAuthenticated(false);
		setUser(null); // Обнуляем данные о пользователе
		localStorage.removeItem("token"); // Удаляем токен из локального хранилища
	};

	return (
		<AuthContext.Provider
			value={{ isAuthenticated, loadingLogin, user, login, logout }}
		>
			{children}
		</AuthContext.Provider>
	);
};

// Хук для доступа к контексту
export const useAuth = () => {
	return useContext(AuthContext);
};
