import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { API_URL } from "../../config";
import { useAuth } from "../../AuthContext";

const useGetKeysHistory = () => {
	const [data, setData] = useState([]); // Инициализация пустым массивом
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const { logout } = useAuth();

	// Функция для получения данных
	const fetchKeys = useCallback(async () => {
		setLoading(true);
		setError(null);

		try {
			const token = localStorage.getItem("token"); // Извлечение токена из локального хранилища

			const response = await axios.get(
				`${API_URL}/keys_management/get_keys_history.php`,
				{
					headers: {
						Authorization: `Bearer ${token}`, // Добавление токена в заголовок
					},
				}
			);

			setData(response.data.reverse());
		} catch (err) {
			if (err.response && err.response.status === 401) {
				logout(); // В случае неавторизованного запроса — выполняем выход из системы
				return false;
			}

			setError(err.message); // Устанавливаем ошибку, если запрос не удался
		} finally {
			setLoading(false); // Завершаем процесс загрузки
		}
	}, [logout]);

	// Загружаем данные при монтировании компонента
	useEffect(() => {
		fetchKeys();
	}, [fetchKeys]); // Заводим хук на загрузку данных только один раз при монтировании компонента

	// Функция для перезагрузки данных
	const refetch = () => {
		fetchKeys();
	};

	return [data, setData, error, loading, refetch];
};

export default useGetKeysHistory;
