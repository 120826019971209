import getWeekNumber from "./getWeekNumber";

const getFBWeekNumber = date => {
	const currentDate = date ? date : new Date();
	const currentYear = currentDate.getFullYear();
	let currentWeek = getWeekNumber(currentDate);

	const weekId = `${currentYear}_${currentWeek}`;
	return weekId;
};

export default getFBWeekNumber;
