import React, { useState } from "react";
import { useDrag } from "react-dnd";
import Tooltip from "@mui/material/Tooltip";
import convertTeam from "../../../utils/translate/translateTeam";

const DraggableItem = ({
	id,
	text,
	className,
	style,
	keyObjekte,
	rhythm,
	team,
	handleOpenModal,
}) => {
	const [{ isDragging }, drag] = useDrag({
		type: "ITEM",
		item: { id },
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
	});

	const [showTooltip, setShowTooltip] = useState(false);

	const handleMouseEnter = () => {
		setShowTooltip(true);
	};

	const handleMouseLeave = () => {
		setShowTooltip(false);
	};

	return (
		<div>
			<Tooltip
				title={
					<span style={{ fontSize: "14px" }}>
						Schlüßelhaken: {keyObjekte ? keyObjekte : "-"}
						<br />
						Rhytmus: {rhythm ? rhythm : "-"}
						<br />
						{team ? convertTeam(team) : ""}
					</span>
				}
				arrow
				placement="top"
				open={showTooltip}
				onClose={() => setShowTooltip(false)}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				sx={{
					pointerEvents: "none", // Игнорировать события мыши для Tooltip
					fontSize: "16px", // Размер шрифта для всплывающего текста
				}}
			>
				<div
					ref={drag}
					data-id={id}
					style={style}
					className={className}
					onClick={() => handleOpenModal(keyObjekte, rhythm, team, text)}
				>
					{text}
				</div>
			</Tooltip>
		</div>
	);
};

export default DraggableItem;
