import { useContext, useEffect, useState } from "react";
import { PageTitleContext } from "../../PageTitleContext";
import { Box, Typography, Button, Snackbar, Alert, CircularProgress } from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import KeyList from "./KeyList"; // Новый компонент для списка ключей
import QrReader from "./QrReader"; // Обновленный компонент для сканирования QR-кодов
import ConfirmDialog from "./ConfirmDialog"; // Компонент подтверждения
import useReturnKey from "../../api/keysManagment/useReturnKeyUser";
import useGetKey from "../../api/keysManagment/useGetKeyUser";
import axios from "axios"; // Импортируем axios для запросов
import { API_URL } from "../../config";

const SchlusselUser = () => {
	const { setTitle } = useContext(PageTitleContext);
	const [takenKeys, setTakenKeys] = useState([]); // Массив для взятых ключей
	const [returnedKeys, setReturnedKeys] = useState([]); // Массив для возвращенных ключей
	const [startScan, setStartScan] = useState(false);
	const [scannedResult, setScannedResult] = useState("");
	const [openDialog, setOpenDialog] = useState(false); // Стейт для управления диалогом
	const [operation, setOperation] = useState(""); // Состояние для операции (например, return или get)
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("success");
	const [loadingKeys, setLoadingKeys] = useState(false); // Флаг загрузки ключей

	const { returnKey, loading: returnLoading } = useReturnKey();
	const { getKey, loading: getLoading } = useGetKey();

	useEffect(() => {
		setTitle("Schlüssel verwalten");
		fetchUserKeys(); // Получаем ключи пользователя
	}, [setTitle]);

	// Функция для запроса данных ключей с сервера
	const fetchUserKeys = async () => {
		setLoadingKeys(true); // Устанавливаем загрузку
		try {
			const token = localStorage.getItem("token"); // Получаем токен авторизации

			// Запрос на сервер для получения ключей пользователя
			const response = await axios.get(`${API_URL}/keys_management/get_all_key_app.php`, {
				headers: {
					Authorization: `Bearer ${token}`, // Передаем токен в заголовке
				},
			});


			if (response.data.length) {
				// Разделяем ключи на взятые и возвращенные

				const data = response?.data?.reverse();

				const taken = data.filter((key) => key.status == 0); // Статус 0 - это взятые ключи
				const returned = data.filter((key) => key.status == 1); // Статус 1 - это возвращенные ключи

				setTakenKeys(taken);
				setReturnedKeys(returned);
			}


		} catch (error) {
			console.error("Ошибка при загрузке данных ключей:", error.message);
			setSnackbarMessage("Fehler beim Laden der Schlüssel.");
			setSnackbarSeverity("error");
		} finally {
			setLoadingKeys(false); // Снимаем загрузку
		}
	};

	// Обработка успешного сканирования QR-кода
	const onScanSuccess = (result) => {
		setScannedResult(result?.data);

		// Анализируем результат сканирования, чтобы понять операцию
		if (result?.data.includes("return")) {
			setOperation("return");
		} else if (result?.data.includes("get")) {
			setOperation("get");
		}

		setOpenDialog(true);
		setStartScan(false);
	};

	// Подтверждение операции (возврат или получение ключа)
	const handleConfirm = async () => {
		const id = scannedResult?.split("-")[1]; // Получаем ID после тире
		let message = "";
		let severity = "success";

		// Найти ключ по ID и обновить его статус
		const updateKeyStatus = (updatedKey) => {
			console.log(updatedKey);

			if (updatedKey.status == "0") {


				setTakenKeys((prev) => [updatedKey, ...prev]); // Добавить во взятые
			} else {
				setTakenKeys((prev) =>
					prev.filter((key) => key.id !== updatedKey.id) // Удалить из взятых
				);
				setReturnedKeys((prev) => [updatedKey, ...prev]); // Добавить в возвращенные
			}
		};

		try {
			if (operation === "return") {
				const response = await returnKey(id);
				if (response) {

					updateKeyStatus(response.data);
					message = "Schlüssel erfolgreich zurückgegeben!";
				} else {
					throw new Error("Fehler bei der Rückgabe des Schlüssels.");
				}
			} else if (operation === "get") {
				const response = await getKey(id);
				if (response) {
					updateKeyStatus(response.data);
					message = "Schlüssel erfolgreich entgegengenommen!";
				} else {
					throw new Error("Fehler bei der Ausgabe des Schlüssels.");
				}
			}
		} catch (error) {
			message = error.message;
			severity = "error";
		}

		setSnackbarMessage(message);
		setSnackbarSeverity(severity);
		setOpenDialog(false);
	};

	// Закрытие диалога без выполнения действия
	const handleCloseDialog = () => {
		setOpenDialog(false);
		setScannedResult(""); // Очистить результат сканирования
	};

	// Перезапуск сканирования
	const handleRestartScan = () => {
		setStartScan(true); // Включаем камеру для нового сканирования
		setScannedResult(""); // Очистить результат сканирования
		setOpenDialog(false); // Закрываем диалог
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				borderRadius: 2,
				p: 3,
			}}
		>
			<Typography variant="h4" gutterBottom mb={3}>
				Schlüsselverwaltung
			</Typography>

			{/* Список взятых ключей */}
			{loadingKeys ? (
				<CircularProgress color="secondary" sx={{ mt: 4, mb: 4 }} />
			) : (
				<KeyList title="Ausgeliehene Schlüssel" keys={takenKeys} defaultExpanded={true} />
			)}

			{/* Список отданных ключей */}
			{loadingKeys ? (
				""
			) : (
				<KeyList title="Zurückgegebene Schlüssel (letzte 14 Tage)" keys={returnedKeys} />
			)}

			{/* Кнопка для сканирования QR-кода */}
			<Box sx={{ mt: 2, mb: 4, display: "flex", justifyContent: "center" }}>
				<Button
					variant="contained"
					color="secondary"
					startIcon={<QrCodeScannerIcon />}
					onClick={() => setStartScan((prev) => !prev)}
				>
					{startScan ? "Scan stoppen" : "Scan starten"}
				</Button>
			</Box>

			{/* Сканирование QR-кода */}
			{startScan && (
				<QrReader
					scannedResult={scannedResult}
					setScannedResult={setScannedResult}
					onScanSuccess={onScanSuccess}
				/>
			)}

			{/* Диалоговое окно подтверждения */}
			<ConfirmDialog
				openDialog={openDialog}
				scannedResult={scannedResult}
				handleCloseDialog={handleCloseDialog}
				handleConfirm={handleConfirm}
				handleRestartScan={handleRestartScan}
				operation={operation}
			/>

			{/* Snackbar для ошибок и успешных операций */}
			<Snackbar
				open={snackbarMessage !== ""}
				autoHideDuration={6000}
				onClose={() => setSnackbarMessage("")}
			>
				<Alert
					onClose={() => setSnackbarMessage("")}
					severity={snackbarSeverity}
				>
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</Box>
	);
};

export default SchlusselUser;
