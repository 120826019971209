import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select"; // Импорт компонента Select
import MenuItem from "@mui/material/MenuItem"; // Импорт компонента MenuItem
import {
	Button,
	Typography,
	Box,
	Snackbar,
	Alert,
	IconButton,
} from "@mui/material";
import { useMode } from "../../theme"; // Предполагается, что useMode остается
import useAddKey from "../../api/keysManagment/useAddKey"; // Предполагается, что хук остается
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Form = () => {
	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();

	const [addNewObject, isLoaded, error] = useAddKey();
	const [message, setMessage] = useState("");
	const [messageType, setMessageType] = useState("success");
	const [openSnackbar, setOpenSnackbar] = useState(false);

	const [city, setCity] = useState("Witten"); // Для хранения выбранного города

	// Обработчик отправки формы
	const onSubmit = async (data) => {
		setMessage(""); // Сбрасываем предыдущее сообщение

		// Конкатенируем objectName и Ort перед отправкой
		const combinedObjectName = `${data.objectName} ${data.Ort}`;

		// Обновляем объект данных
		const updatedData = { ...data, objectName: combinedObjectName };

		// Создание FormData для отправки данных на сервер
		const formData = new FormData();
		formData.append("objectName", updatedData.objectName);

		// Печать данных для отладки
		for (let pair of formData.entries()) {
			console.log(pair[0] + ": " + pair[1]);
		}

		try {
			await addNewObject(formData); // Отправка данных на сервер
			setMessage("Objekt erfolgreich hinzugefügt!"); // Успешное уведомление
			setMessageType("success");
			setOpenSnackbar(true);
			reset(); // Сбрасываем состояние формы после успешной отправки

			// Убираем сообщение через 2,5 секунды
			setTimeout(() => {
				setMessage("");
				setOpenSnackbar(false);
			}, 2500);
		} catch (err) {
			setMessage(err); // Ошибка отправки
			setMessageType("error");
			setOpenSnackbar(true);

			setTimeout(() => {
				setMessage("");
				setOpenSnackbar(false);
			}, 2500);
		}
	};

	// Обработчик изменения города
	const handleCityChange = (event) => {
		setCity(event.target.value);
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Box display="flex" gap={3} flexWrap="wrap">
					{/* Поле для названия объекта */}
					<div>
						<Controller
							name="objectName"
							control={control}
							defaultValue=""
							rules={{ required: "Der Objektname ist erforderlich" }}
							render={({ field }) => (
								<TextField
									{...field}
									label="Objektname"
									variant="filled"
									error={!!errors.objectName}
									helperText={errors.objectName ? errors.objectName.message : ""}
									sx={{ width: "300px" }}
								/>
							)}
						/>
					</div>

					{/* Поле для выбора города (Select вместо TextField) */}
					<div>
						<Controller
							name="Ort"
							control={control}
							defaultValue={city}
							render={({ field }) => (
								<Select
									{...field}
									labelId="city-select-label"
									id="city-select"
									value={city}
									label="Ort"
									onChange={(e) => {
										handleCityChange(e); // Обновляем состояние города
										field.onChange(e); // Обновляем контроллер формы
									}}
									sx={{ width: "300px" }}
								>
									<MenuItem value="Witten">Witten</MenuItem>
									<MenuItem value="Witten-Annen">Witten Annen</MenuItem>
									<MenuItem value="Witten-Rüdinghausen">Witten Rüdinghausen</MenuItem>
									<MenuItem value="Witten-Stockum">Witten Stockum</MenuItem>
									<MenuItem value="Witten-Bommern">Witten Bommern</MenuItem>
									<MenuItem value="Witten-Heven">Witten Heven</MenuItem>
									<MenuItem value="Witten-Herbede">Witten Herbede</MenuItem>
									<MenuItem value="Dortmund">Dortmund</MenuItem>
									<MenuItem value="Bochum">Bochum</MenuItem>
									<MenuItem value="Essen">Essen</MenuItem>
									<MenuItem value="Hattingen">Hattingen</MenuItem>
									<MenuItem value="Wetter">Wetter</MenuItem>
									<MenuItem value="Herdecke">Herdecke</MenuItem>
									<MenuItem value="Recklinghausen">Recklinghausen</MenuItem>
									<MenuItem value="Herne">Herne</MenuItem>
									<MenuItem value="Menden">Menden</MenuItem>
									<MenuItem value="Gelsenkirchen">Gelsenkirchen</MenuItem>
									<MenuItem value="Hagen">Hagen</MenuItem>
									<MenuItem value="Ennepetal">Ennepetal</MenuItem>

								</Select>
							)}
						/>
					</div>
				</Box>

				{/* Кнопка отправки формы */}
				<Button
					type="submit"
					color="success"
					variant="contained"
					sx={{ marginTop: "30px" }}
				>
					Objekt Hinzufügen
				</Button>
			</form>

			{/* Snackbar для уведомлений об ошибке или успехе */}
			<Snackbar
				open={openSnackbar}
				autoHideDuration={2500}
				onClose={() => setOpenSnackbar(false)}
			>
				<Alert
					onClose={() => setOpenSnackbar(false)}
					severity={messageType}
					sx={{ width: "100%" }}
				>
					{message}
				</Alert>
			</Snackbar>
		</>
	);
};

export default Form;
