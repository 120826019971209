// src/App.js
import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";

import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import { ColorModeContext, useMode } from "./theme";
import { AuthProvider, useAuth } from "./AuthContext"; // Импортируем AuthProvider

import { PageTitleProvider } from "./PageTitleContext";
import Scheduling from "./scenes/scheduling";
import AddObjekt from "./scenes/addObjekt";
import DeleteObjekt from "./scenes/deleteObjekt";
import LoadingInfo from "./components/UI/LoadingInfo/LoadingInfo";
import LoginMain from "./components/Login/LoginMain";
import SchlusselAdd from "./scenes/schlusselAdd";
import SchlusselVerlauf from "./scenes/schlusselVerlauf";
import SchlusselVerwalten from "./scenes/schlusselVerwalten";
import UsersVerwalten from "./scenes/usersVerwalten";
import UserAdd from "./scenes/userAdd";
import QRCodeGenerator from "./scenes/QrCode";
import SchlusselUser from "./scenes/schlusselUser";
import Profile from "./scenes/profile";

function App() {
	const [theme, colorMode] = useMode();

	return (
		<ColorModeContext.Provider value={colorMode}>
			<PageTitleProvider>
				<AuthProvider>
					{/* Оборачиваем в AuthProvider */}
					<ThemeProvider theme={theme}>
						<CssBaseline />
						<MainContent />
					</ThemeProvider>
				</AuthProvider>
			</PageTitleProvider>
		</ColorModeContext.Provider>
	);
}

const MainContent = () => {
	const { isAuthenticated, loadingLogin, user } = useAuth(); // Используем хук для получения статуса аутентификации
	const [isCollapsed, setIsCollapsed] = useState(true);

	return (
		<div className="app">
			<>
				<Sidebar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />

				<main className="content">
					<Topbar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
					{loadingLogin ? (
						<LoadingInfo />
					) : isAuthenticated ? (
						<Routes>
							<Route path="*" element={<Navigate to="/" replace />} />

							{user?.role === "admin" && (
								<>
									<Route path="/" element={<Profile />} />
									<Route path="/planenObjekt" element={<Scheduling />} />
									<Route path="/addObjekt" element={<AddObjekt />} />
									<Route path="/deleteObjekt" element={<DeleteObjekt />} />
									<Route
										path="/schlusselVerwalten"
										element={<SchlusselVerwalten />}
									/>
									<Route
										path="/schlusselVerlauf"
										element={<SchlusselVerlauf />}
									/>
									<Route path="/addSchlussel" element={<SchlusselAdd />} />
									<Route path="/usersVerwalten" element={<UsersVerwalten />} />
									<Route path="/addUser" element={<UserAdd />} />
								</>
							)}

							{user?.role === "worker" && (
								<>
									<Route path="/" element={<Profile />} />
									<Route path="/schlussel" element={<SchlusselUser />} />
								</>
							)}
						</Routes>
					) : (
						<>
							<Routes>
								<Route path="/" element={<LoginMain />} />
								<Route path="*" element={<Navigate to="/" replace />} />
							</Routes>
						</>
					)}
				</main>
			</>
		</div>
	);
};

export default App;
