import { Box, Button, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import { PageTitleContext } from "../../PageTitleContext";

import ProfilImg from "../../img/profile-img.webp";
import { useAuth } from "../../AuthContext";

import MenuIcon from "@mui/icons-material/Menu"; // Иконка меню


import { Link } from "react-router-dom";

const Topbar = ({ isCollapsed, setIsCollapsed }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const colorMode = useContext(ColorModeContext);
	const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Проверка для мобильных


	const { title } = useContext(PageTitleContext);
	const { logout } = useAuth();

	const { isAuthenticated, loadingLogin, user } = useAuth(); // Используем хук для получения статуса аутентификации

	return (
		<Box
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			p={2}
		>
			{/* Левая часть — название страницы или бургер-меню для мобильных */}
			<Box display="flex" alignItems="center">
				{isMobile && (
					loadingLogin ? (
						""
					) : isAuthenticated ? (
						<IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
							<MenuIcon />
						</IconButton>
					) : (
						""
					)

				)}
				{loadingLogin ? (
					""
				) : isAuthenticated ? (
					<Typography variant={isMobile ? "h6" : "h4"} sx={{ m: "10px 15px" }}>
						{title}
					</Typography>
				) : (
					""
				)}
			</Box>

			{/* Правая часть — иконки и элементы авторизации */}
			<Box display="flex" alignItems="center">
				{/* Вход/выход из аккаунта */}
				{loadingLogin ? (
					""
				) : isAuthenticated ? (
					<>
						<Link to="/">
							<Box
								width="40px"
								height="40px"
								sx={{ display: isMobile ? "block" : "none" }}
							>
								<img
									width="40px"
									height="40px"
									src={user.photo_path ? user.photo_path : ProfilImg}
									alt="Profile"
									style={{ borderRadius: "50%", objectFit: "cover" }}
								/>
							</Box>
						</Link>

						<Box display="flex" alignItems="center">
							{/* Имя пользователя и аватар */}
							<Link to="/" style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>

								<Typography
									variant="h5"
									sx={{
										marginRight: 2,
										marginLeft: 4,
										color: theme.palette.text.primary,
										display: isMobile ? "none" : "block",
									}}
								>
									{user.username}
								</Typography>
								<Box
									width="40px"
									height="40px"
									sx={{ display: isMobile ? "none" : "block" }}
								>
									<img
										width="40px"
										height="40px"
										src={user.photo_path ? user.photo_path : ProfilImg}
										alt="Profile"
										style={{ borderRadius: "50%", objectFit: "cover" }}
									/>
								</Box>
							</Link>

							<Button
								variant="contained"
								color="secondary"
								sx={{
									borderRadius: 4,
									paddingX: 3,
									marginLeft: 2,
									display: isMobile ? "none" : "block",
								}}
								onClick={logout}
							>
								Ausloggen
							</Button>
						</Box>
					</>
				) : (
					""
				)}
			</Box>
		</Box>
	);
};

export default Topbar;
