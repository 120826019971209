import React from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	IconButton,
	TableSortLabel,
} from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";

const UserTable = ({
	users,
	handleEditClick,
	handleDeleteClick,
	order,
	orderBy,
	handleRequestSort,
}) => (
	<TableContainer component={Paper}>
		<Table>
			<TableHead>
				<TableRow>
					<TableCell>
						<TableSortLabel
							active={orderBy === "id"}
							direction={orderBy === "id" ? order : "asc"}
							onClick={() => handleRequestSort("id")}
						>
							ID
						</TableSortLabel>
					</TableCell>
					<TableCell>Foto</TableCell>
					<TableCell>
						<TableSortLabel
							active={orderBy === "username"}
							direction={orderBy === "username" ? order : "asc"}
							onClick={() => handleRequestSort("username")}
						>
							Benutzername
						</TableSortLabel>
					</TableCell>
					<TableCell>
						<TableSortLabel
							active={orderBy === "role"}
							direction={orderBy === "role" ? order : "asc"}
							onClick={() => handleRequestSort("role")}
						>
							Rolle
						</TableSortLabel>
					</TableCell>
					<TableCell align="right">Aktionen</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{users.map(user => (
					<TableRow
						key={user.id}
						onClick={() => handleEditClick(user)}
						sx={{
							cursor: "pointer",
							transition: "background-color 0.3s ease",
							"&:hover": {
								backgroundColor: "rgba(0, 151, 255, 0.15)",
							},
						}}
					>
						<TableCell>{user.id}</TableCell>
						<TableCell>
							<img
								src={user.photo_path}
								alt={user.username}
								style={{
									width: 30,
									height: 30,
									borderRadius: "50%",
									objectFit: "cover",
								}}
							/>
						</TableCell>
						<TableCell>{user.username}</TableCell>
						<TableCell>{user.role}</TableCell>
						<TableCell align="right">
							<IconButton
								onClick={e => handleDeleteClick(e, user)}
								color="error"
							>
								<DeleteIcon />
							</IconButton>
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	</TableContainer>
);

export default UserTable;
