const convertFBWeek = str => {
	const parts = str.split("_");
	if (parts.length >= 2) {
		return parseInt(parts[1]);
	} else {
		return null; // Если строка не соответствует формату, возвращаем null
	}
};

export default convertFBWeek;
