import React, { useState, useContext, useEffect } from "react";
import { Button, Typography, CircularProgress, TextField } from "@mui/material";
import { PageTitleContext } from "../../PageTitleContext";
import useGetAllObejkte from "../../api/useGetAllObejkte";
import getColor from "../../hooks/useGetColor";
import DialogModal from "./DialogModal";
import useDeleteObjektsAPI from "../../api/useDeleteObjektsAPI";
import ErrorAddresse from "../scheduling/Error/ErrorAddresse";
import DeleteIcon from "@mui/icons-material/Delete";

function DeleteObjekt() {
	const [activeObject, setActiveObjects] = useState([]);
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState({ success: null, text: "" });
	const [searchText, setSearchText] = useState("");
	const [filteredObjekts, setFilteredObjekts] = useState([]);
	const [addresse, setAddresse] = useState([]);

	const { setTitle } = useContext(PageTitleContext);
	useEffect(() => {
		setTitle("Objekt löschen");
	}, [setTitle]);

	const [data, setData, error, loading] = useGetAllObejkte();
	const deleteObjektsAPI = useDeleteObjektsAPI(); // вызов хука удаления объектов

	useEffect(() => {
		if (!loading) {
			setAddresse(data);
		}
	}, [loading, data]);

	function filterObjectsById(objectsArray, excludedIds) {
		return objectsArray.filter(obj => !excludedIds.includes(obj.id));
	}

	const handleItemClick = item => {
		setActiveObjects(prevActiveObjects => {
			if (prevActiveObjects.some(activeObject => activeObject.id === item.id)) {
				return prevActiveObjects.filter(
					activeObject => activeObject.id !== item.id
				);
			} else {
				return [...prevActiveObjects, item];
			}
		});
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const deleteObjekts = async () => {
		const arrIds = activeObject.map(item => item.id);
		handleClose();

		try {
			const dataAPI = await deleteObjektsAPI(arrIds);
			if (dataAPI && dataAPI.deleted_ids.length) {
				setMessage({
					success: true,
					text: "Objekten wurden erfolgreich gelöscht!",
				});
				setTimeout(() => {
					setMessage({ success: null, text: "" });
				}, 2500);

				const newAddresse = filterObjectsById(addresse, arrIds);
				setAddresse(newAddresse);
				setActiveObjects([]);
			} else {
				setMessage({
					success: false,
					text: "Objekten wurden nicht gelöscht. Versuchen Sie später nochmal",
				});
			}
		} catch (error) {
			console.error("Ошибка:", error);
			setMessage({
				success: false,
				text: "Objekten wurden nicht gelöscht. Versuchen Sie später nochmal",
			});
		}
	};

	const searchAdresse = e => {
		setSearchText(e.target.value);
		const filteredObjects = data.filter(obj =>
			obj.address.toLowerCase().includes(e.target.value.toLowerCase())
		);
		setAddresse(filteredObjects);
	};

	return (
		<div className="deleteObjekt">
			<div className="deleteObjekt__content">
				<Typography variant="h3" sx={{ marginTop: "30px" }}>
					Hier können Sie Objekten löschen.
				</Typography>
				<div className="deleteObjekt__header">
					<TextField
						id="search-address"
						label="Suchen"
						variant="outlined"
						sx={{ width: "400px" }}
						value={searchText}
						onChange={searchAdresse}
					/>
					<div className="deleteObjekt__header-right">
						<div
							className={`deleteObjekt__header-message ${
								message.success ? "_success" : "_error"
							}`}
						>
							{message.text}
						</div>
						<Button
							variant="contained"
							color="error"
							onClick={handleClickOpen}
							disabled={activeObject.length === 0}
							startIcon={<DeleteIcon />}
						>
							Löschen : {activeObject.length}
						</Button>
					</div>
				</div>
				<div className="deleteObjekt__body">
					{loading ? (
						<div className="scheduling__content-err">
							<CircularProgress color="secondary" />
							<Typography variant="h6">Daten laden...</Typography>
						</div>
					) : error ? (
						<ErrorAddresse err={"Etwas ist schief gelaufen"} />
					) : (
						addresse.map(item => {
							const streetStyle = {
								backgroundColor: getColor(item.city).backgroundColor,
								color: getColor(item.city).color,
							};

							const isActive = activeObject.some(
								activeObject => activeObject.id === item.id
							);

							return (
								<div
									key={item.id}
									data-id={item.id}
									style={streetStyle}
									className={`deleteObjekt__item ${isActive ? "_active" : ""}`}
									onClick={() => handleItemClick(item)}
								>
									{item.address}
								</div>
							);
						})
					)}
				</div>
			</div>

			<DialogModal
				open={open}
				handleClose={handleClose}
				loading={loading}
				activeObject={activeObject}
				deleteObjekts={deleteObjekts}
			/>
		</div>
	);
}

export default DeleteObjekt;
