import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import {
	Button,
	Typography,
	Box,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	Snackbar,
	Alert,
	IconButton,
} from "@mui/material";
import useAddUser from "../../api/users/useAddUser";

import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const FormUser = () => {
	const {
		control,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();
	const { addUser, loading, error } = useAddUser();

	const [message, setMessage] = useState("");
	const [messageType, setMessageType] = useState("success");
	const [openSnackbar, setOpenSnackbar] = useState(false);

	const [photoFile, setPhotoFile] = useState(null);

	// Состояние для видимости пароля
	const [showPassword, setShowPassword] = useState(false);

	// Обработчик изменения файла
	const handleFileChange = e => {
		const file = e.target.files[0];
		if (file) {
			setPhotoFile(file);
		}
	};

	// Переключение видимости пароля
	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const onSubmit = async data => {
		const formData = new FormData();

		formData.append("username", data.username);
		formData.append("password", data.password);
		formData.append("role", data.role);

		if (photoFile) {
			formData.append("photo", photoFile);
		}

		for (let pair of formData.entries()) {
			console.log(pair[0] + ": " + pair[1]);
		}
		try {
			const response = await addUser(formData);
			console.log(response);

			if (response && response.success) {
				setMessage("Benutzer erfolgreich hinzugefügt!");
				setMessageType("success");
				setOpenSnackbar(true);
				reset();
				setPhotoFile(null);
			}
		} catch (err) {
			setMessage(err.message);
			setMessageType("error");
			setOpenSnackbar(true);
		}

		setTimeout(() => {
			setOpenSnackbar(false);
			setMessage("");

		}, 2500);
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Box display="flex" gap={3} flexWrap="wrap">
					{/* Поле для имени пользователя */}
					<div>
						<Controller
							name="username"
							control={control}
							defaultValue=""
							rules={{ required: "Benutzername ist erforderlich" }}
							render={({ field }) => (
								<TextField
									{...field}
									label="Benutzername"
									variant="filled"
									error={!!errors.username}
									helperText={errors.username ? errors.username.message : ""}
									sx={{ width: "300px" }}
								/>
							)}
						/>
					</div>

					{/* Поле для пароля */}
					<div>
						<Controller
							name="password"
							control={control}
							defaultValue=""
							rules={{ required: "Passwort ist erforderlich" }}
							render={({ field }) => (
								<TextField
									{...field}
									label="Passwort"
									type={showPassword ? "text" : "password"} // Условие для переключения типа поля
									variant="filled"
									error={!!errors.password}
									helperText={errors.password ? errors.password.message : ""}
									sx={{ width: "300px" }}
									InputProps={{
										endAdornment: (
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												edge="end"
											>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										),
									}}
								/>
							)}
						/>
					</div>

					{/* Поле для роли */}
					<div>
						<FormControl
							fullWidth
							error={!!errors.role}
							sx={{ width: "300px" }}
						>
							<InputLabel id="role-label">Rolle</InputLabel>
							<Controller
								name="role"
								control={control}
								defaultValue=""
								rules={{ required: "Rolle ist erforderlich" }}
								render={({ field }) => (
									<Select {...field} labelId="role-label" label="Rolle">
										<MenuItem value="admin">Admin</MenuItem>
										<MenuItem value="worker">Mitarbeiter</MenuItem>
									</Select>
								)}
							/>
							{errors.role && (
								<Typography variant="body2" color="error">
									{errors.role.message}
								</Typography>
							)}
						</FormControl>
					</div>

					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						gap="30px"
					>
						<Box>
							{/* Скрытое поле для загрузки файлов */}
							<input
								type="file"
								onChange={handleFileChange}
								accept="image/*"
								style={{ display: "none" }}
								id="file-upload"
							/>
							{/* Кнопка для загрузки изображения с иконкой */}
							<label htmlFor="file-upload">
								<Button
									color={"secondary"}
									variant="contained"
									component="span"
									startIcon={<PhotoCameraIcon />}
								>
									{photoFile ? "Foto Ausgewählt" : "Foto Hochladen"}
								</Button>
							</label>
						</Box>

						{/* Отображение выбранной фотографии */}
						{photoFile && (
							<Box
								sx={{
									mt: 2,
									textAlign: "center",
									width: "150px", // Размер картинки можно настроить
									height: "150px", // Размер картинки можно настроить
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									borderRadius: "50%", // Округлые края для изображения
									overflow: "hidden", // Обрезаем изображение по форме
									border: "2px solid #ccc", // Добавляем границу для изображения
								}}
							>
								<img
									src={URL.createObjectURL(photoFile)}
									alt="Preview"
									style={{
										width: "100%",
										height: "100%",
										objectFit: "cover",
									}}
								/>
							</Box>
						)}
					</Box>
				</Box>

				{/* Кнопка отправки формы */}
				<Button
					type="submit"
					color="success"
					variant="contained"
					sx={{ marginTop: "30px" }}
				>
					Benutzer Hinzufügen
				</Button>
			</form>

			{/* Snackbar для уведомлений об ошибке или успехе */}
			<Snackbar
				open={openSnackbar}
				autoHideDuration={2500}
				onClose={() => setOpenSnackbar(false)}
			>
				<Alert
					onClose={() => setOpenSnackbar(false)}
					severity={messageType}
					sx={{ width: "100%" }}
				>
					{message}
				</Alert>
			</Snackbar>
		</>
	);
};

export default FormUser;
