import { useState } from "react";
import axios from "axios";
import { API_URL } from "../config";
import { useAuth } from "../AuthContext";

const useAssignmentApi = () => {
	const [isLoadedAssignment, setIsLoadedAssignment] = useState(null);
	const [errorAssignment, setError] = useState(null);
	const { logout } = useAuth();
	const token = localStorage.getItem("token"); // Извлечение токена из локального хранилища

	const addToAssignment = async (data, week, day, team) => {
		setIsLoadedAssignment(false);
		try {
			const requestData = {
				...data,
				week,
				day,
				team,
			};

			const response = await axios.post(
				`${API_URL}/set_weekly_assignments.php`,
				requestData,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`, // Добавление токена в заголовок
					},
				}
			);

			await removeWeeklyUnassignment(data.id, week);
			setIsLoadedAssignment(true);
			return response.data; // Возвращаем данные, если нужно
		} catch (error) {
			if (error.response.status === 401) {
				logout();
			}
			setError(error.response?.data.error || "Fehler");
			setIsLoadedAssignment(true);
			throw error; // Пробрасываем ошибку для обработки в вызывающем коде
		}
	};

	const removeFromAssigment = async (data, week, day, team) => {
		setIsLoadedAssignment(false);
		try {
			const requestData = {
				...data,
				week,
				day,
				team,
			};

			await axios.post(`${API_URL}/set_weekly_unassignments.php`, requestData, {
				headers: {
					Authorization: `Bearer ${token}`, // Добавление токена в заголовок
				},
			});

			const requestDataRemove = {
				week,
				day,
				team,
				id: data.id,
			};

			await axios.post(
				`${API_URL}/remove_weekly_assignment.php`,
				requestDataRemove,
				{
					headers: {
						Authorization: `Bearer ${token}`, // Добавление токена в заголовок
					},
				}
			);

			setIsLoadedAssignment(true);
		} catch (error) {
			if (error.response.status === 401) {
				logout();
			}
			setError(error.response?.data.error || "Fehler");
			setIsLoadedAssignment(true);
			throw error; // Пробрасываем ошибку для обработки в вызывающем коде
		}
	};

	const removeWeeklyUnassignment = async (id, week) => {
		try {
			const removeData = {
				week,
				id,
			};

			await axios.post(
				`${API_URL}/remove_weekly_unassignment.php`,
				removeData,
				{
					headers: {
						Authorization: `Bearer ${token}`, // Добавление токена в заголовок
					},
				}
			);
		} catch (error) {
			if (error.response.status === 401) {
				logout();
			}
			console.error("Ошибка при удалении недельного назначения:", error);
			throw error;
		}
	};

	return {
		addToAssignment,
		removeFromAssigment,
		isLoadedAssignment,
		errorAssignment,
	};
};

export default useAssignmentApi;
