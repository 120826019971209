import React, { useState, useEffect, useRef } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { IconButton, Typography } from "@mui/material";
import { startOfWeek, endOfWeek, addWeeks, format } from "date-fns";

const WeekCalendar = ({
	currentDate,
	setCurrentDate,
	setLeftItems,
	setRightItems,
	team,
	setTeam,
	isLoaded,
}) => {
	const today = new Date();
	const [currentStartOfWeek, setCurrentStartOfWeek] = useState(
		startOfWeek(today, { weekStartsOn: 1 })
	);
	const [currentEndOfWeek, setCurrentEndOfWeek] = useState(
		endOfWeek(today, { weekStartsOn: 1 })
	);
	const [buttonsDisabled, setButtonsDisabled] = useState(false);
	const timerRef = useRef(null);

	useEffect(() => {
		setCurrentDate(currentStartOfWeek);
	}, [currentStartOfWeek, setCurrentDate]);

	useEffect(() => {
		// Устанавливаем кнопки неактивными при загрузке данных
		setButtonsDisabled(!isLoaded);

		// Если кнопки остаются неактивными более 3 секунд, активируем их
		if (!isLoaded) {
			timerRef.current = setTimeout(() => {
				setButtonsDisabled(false);
			}, 3000); // Задержка в 3000 мс (3 секунды)
		} else {
			clearTimeout(timerRef.current);
			setButtonsDisabled(false); // В случае, если данные загрузились раньше, делаем кнопки активными сразу
		}

		return () => clearTimeout(timerRef.current); // Очищаем таймер при размонтировании компонента
	}, [isLoaded]);

	const getWeekNumber = date => {
		const startOfYear = new Date(date.getFullYear(), 0, 1);
		const pastDaysOfYear = (date - startOfYear) / 86400000;
		return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
	};

	const changeWeek = increment => {
		// Убираем сразу элементы с левой и правой части, чтобы не было задержки
		setLeftItems([]);
		setRightItems([]);

		if (team === "Alle Touren") {
			setTeam("Team1");
		}

		const newStartOfWeek = addWeeks(currentStartOfWeek, increment);
		const newEndOfWeek = addWeeks(currentEndOfWeek, increment);
		setCurrentStartOfWeek(newStartOfWeek);
		setCurrentEndOfWeek(newEndOfWeek);
	};

	const isBeforeMinDate = date => {
		const startOfCurrentWeek = startOfWeek(today, { weekStartsOn: 1 });
		return date < startOfCurrentWeek;
	};

	const isAfterMaxDate = date => {
		const endOfNextWeek = endOfWeek(addWeeks(today, 1), { weekStartsOn: 1 });
		return date > endOfNextWeek;
	};

	return (
		<div className="calendar">
			<div className="calendar__content">
				<IconButton
					className="calendar__button btn"
					onClick={() => changeWeek(-1)}
					disabled={buttonsDisabled || isBeforeMinDate(currentStartOfWeek)}
				>
					<KeyboardArrowLeftIcon />
				</IconButton>
				&nbsp;&nbsp;
				<Typography variant="h5" className="calendar__week">
					Woche {getWeekNumber(currentStartOfWeek)}
				</Typography>
				&nbsp;
				<Typography variant="h6" className="calendar__year">
					({format(currentStartOfWeek, "dd.MM.yyyy")}
					&nbsp;-&nbsp;
					{format(currentEndOfWeek, "dd.MM.yyyy")})
				</Typography>
				&nbsp;&nbsp;
				<IconButton
					className="calendar__button btn"
					onClick={() => changeWeek(1)}
					disabled={buttonsDisabled || isAfterMaxDate(currentEndOfWeek)}
				>
					<KeyboardArrowRightIcon />
				</IconButton>
			</div>
		</div>
	);
};

export default WeekCalendar;
