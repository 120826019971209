import React, { useEffect, useState } from "react";
import Weekdays from "../../components/Weekdays/Weekdays";
import PDFButton from "../../components/UI/Buttons/PDFButton";
import DroppableArea from "./DragAndDrop/DroppableArea";
import Addresses from "./Addresses/Addresses";
import { CircularProgress, Typography } from "@mui/material";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Импорт иконки галочки
import ErrorIcon from "@mui/icons-material/Error";
import generatePlanPDF from "../../utils/generatePlanPDF";
import TeamsSelect from "../../components/TeamsSelect/TeamsSelect";

function RightSide({
	rightItems,
	handleDrop,
	setWeekday,
	weekday,
	weekFB,
	setTeam,
	team,
	isLoaded2,
	isLoadedAssignment,
	assignmentError,
	handleOpenModal,
}) {
	const [showCheck, setShowCheck] = useState(false); // Состояние для отображения галочки

	useEffect(() => {
		if (isLoadedAssignment) {
			setShowCheck(true);
		} else {
			setShowCheck(false);
		}
	}, [isLoadedAssignment]);

	const handleWeekdayChange = event => {
		setWeekday(event.target.value);
	};

	const handleTeamChange = event => {
		setTeam(event.target.value);
	};

	const printAddresse = () => {
		// Создаем PDF
		generatePlanPDF(rightItems, team, weekFB, weekday);
	};

	return (
		<>
			<div className="addresses__left-top">
				<div className="addresses__left-selects">
					<Weekdays
						setWeekday={setWeekday}
						weekday={weekday}
						handleChange={handleWeekdayChange}
					/>
					<TeamsSelect team={team} handleChange={handleTeamChange} />
				</div>
				<PDFButton onClick={printAddresse}>Ausdrucken</PDFButton>
				<div className="addresses__left-progress">
					{isLoadedAssignment === false ? (
						<div style={{ display: "flex", alignItems: "center" }}>
							<CircularProgress
								size={17}
								color="secondary"
								className="fade-animation"
							/>
						</div>
					) : assignmentError ? (
						<div style={{ display: "flex", alignItems: "center" }}>
							<span>Das Objekt wurde nicht erfolgreich verschoben</span>&nbsp;
							<ErrorIcon fontSize="small" />
						</div>
					) : (
						""
					)}
					{showCheck && !assignmentError ? (
						<CheckCircleIcon
							style={{ color: "green", marginRight: "8px", fontSize: 17 }}
							className="fade-animation"
						/>
					) : (
						""
					)}
				</div>
			</div>

			<div className="addresses__city">
				<DroppableArea onItemDropped={handleDrop} target="right">
					{isLoaded2 ? (
						rightItems.length ? (
							<Addresses
								isLoadedAssignment={isLoadedAssignment}
								items={rightItems}
								handleOpenModal={handleOpenModal}
							/>
						) : (
							<Typography className="addresses__no-addresse" variant="h3">
								Keine Adressen gefunden &nbsp;
								<EventBusyIcon />
							</Typography>
						)
					) : (
						<div className="scheduling__content-err _r">
							<CircularProgress color="secondary" />
						</div>
					)}
				</DroppableArea>
			</div>
		</>
	);
}

export default RightSide;
