import * as React from "react";
import { InputLabel, Select } from "@mui/material";
import { MenuItem } from "@mui/material";

const styles = {
	select: {
		width: "200px", // Ширина селекта
		borderRadius: "4px", // Закругленные углы
		border: "1px solid #ced4da", // Граница
		padding: "2px", // Отступы
	},
};

export default function Weekdays({ weekday, handleChange }) {
	return (
		<div>
			<InputLabel>Wochentag</InputLabel>
			<Select
				value={weekday}
				label="Wochentag"
				defaultValue={weekday}
				onChange={handleChange}
				sx={styles.select} // Применяем стили к селекту
			>
				<MenuItem value={"Monday"}>Montag</MenuItem>
				<MenuItem value={"Tuesday"}>Dienstag</MenuItem>
				<MenuItem value={"Wednesday"}>Mittwoch</MenuItem>
				<MenuItem value={"Thursday"}>Donnerstag</MenuItem>
				<MenuItem value={"Friday"}>Freitag</MenuItem>
				<MenuItem value={"Saturday"}>Samstag</MenuItem>
				<MenuItem value={"Sunday"}>Sonntag</MenuItem>
			</Select>
		</div>
	);
}
