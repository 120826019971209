import React, { useState } from "react";
import {
	TableContainer,
	Paper,
	TextField,
	Typography,
	CircularProgress,
	Box,
	Snackbar,
	Alert,
} from "@mui/material";
import useGetUsers from "../../api/users/useGetUsers";
import useUpdateUser from "../../api/users/useUpdateUser";
import useDeleteUser from "../../api/users/useDeleteUser";
import UserTable from "./UserTable";
import EditUserModal from "./EditUserModal";
import DeleteUserModal from "./DeleteUserModal";

const TableUsers = () => {
	const { users, loading, error, setUsers } = useGetUsers();
	const { deleteUser } = useDeleteUser();
	const { saveUser } = useUpdateUser();

	const [openEditModal, setOpenEditModal] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [userToDelete, setUserToDelete] = useState(null);
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("id");
	const [searchQuery, setSearchQuery] = useState("");
	const [snack, setSnack] = useState({
		open: false,
		message: "",
		severity: "success", // "success", "error", "warning", "info"
	});

	// Показать уведомление
	const showSnack = (message, severity = "success") => {
		setSnack({ open: true, message, severity });
	};

	// Закрыть уведомление
	const handleCloseSnack = () => {
		setSnack({ ...snack, open: false });
	};

	// Обработчик клика на редактирование пользователя
	const handleEditClick = user => {
		setSelectedUser(user);
		setOpenEditModal(true);
	};

	// Обработчик клика на удаление пользователя
	const handleDeleteClick = (event, user) => {
		event.stopPropagation();
		setUserToDelete(user);
		setOpenDeleteModal(true);
	};

	// Обработчик сортировки таблицы
	const handleRequestSort = property => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	// Обработчик сохранения изменений
	const handleSave = async user => {
		try {
			const updatedUser = await saveUser(user);

			// Синхронизируем изменения в таблице
			setUsers(prevUsers =>
				prevUsers.map(u =>
					u.id === updatedUser?.user?.id ? updatedUser.user : u
				)
			);
			showSnack("Benutzer erfolgreich aktualisiert.", "success");
			setOpenEditModal(false); // Закрыть модалку после успешного сохранения
		} catch (e) {
			showSnack(e.message, "error");
		}
	};

	// Обработчик удаления пользователя
	const handleDeleteUser = async () => {
		try {
			await deleteUser(userToDelete.id);
			setUsers(users.filter(user => user.id !== userToDelete.id));
			showSnack("Benutzer erfolgreich gelöscht.", "success");
			setOpenDeleteModal(false); // Закрыть модалку после удаления
		} catch (e) {
			showSnack(e.message, "error");
		}
	};

	// Фильтрация пользователей по запросу поиска
	const filteredUsers = users.filter(user =>
		user.username.toLowerCase().includes(searchQuery.toLowerCase())
	);

	// Сортировка пользователей
	const sortedUsers = filteredUsers.sort((a, b) => {
		const isAsc = order === "asc";
		if (a[orderBy] < b[orderBy]) return isAsc ? -1 : 1;
		if (a[orderBy] > b[orderBy]) return isAsc ? 1 : -1;
		return 0;
	});

	// Загрузка данных
	if (loading) {
		return (
			<Paper style={{ padding: "50px", textAlign: "center" }}>
				<CircularProgress color="secondary" />
				<Typography variant="h6" paddingTop="32px">
					Daten werden geladen...
				</Typography>
			</Paper>
		);
	}

	// Ошибка при загрузке данных
	if (error) {
		return (
			<Paper style={{ padding: "50px", textAlign: "center", color: "#C70039" }}>
				<Typography variant="h3">Ein Fehler ist aufgetreten:</Typography>
				<Typography variant="h4" paddingTop="32px">
					{error}
				</Typography>
			</Paper>
		);
	}

	return (
		<>
			<Box display="flex" justifyContent="flex-start" mb={2}>
				<TextField
					label="Suchen"
					variant="outlined"
					value={searchQuery}
					onChange={e => setSearchQuery(e.target.value)}
					style={{ width: "400px", marginBottom: "20px", marginTop: 0 }}
					placeholder="Benutzername suchen"
				/>
			</Box>
			<UserTable
				users={sortedUsers}
				handleEditClick={handleEditClick}
				handleDeleteClick={handleDeleteClick}
				order={order}
				orderBy={orderBy}
				handleRequestSort={handleRequestSort}
			/>
			<EditUserModal
				open={openEditModal}
				user={selectedUser}
				onClose={() => setOpenEditModal(false)}
				onSave={handleSave}
				onChange={setSelectedUser} // Обновление данных пользователя в родительском компоненте
			/>
			<DeleteUserModal
				open={openDeleteModal}
				onClose={() => setOpenDeleteModal(false)}
				onDelete={handleDeleteUser}
			/>
			<Snackbar
				open={snack.open}
				autoHideDuration={4000}
				onClose={handleCloseSnack}
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }} // Уведомления слева
			>
				<Alert
					onClose={handleCloseSnack}
					severity={snack.severity}
					sx={{ width: "100%" }}
				>
					{snack.message}
				</Alert>
			</Snackbar>
		</>
	);
};

export default TableUsers;
