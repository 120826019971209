import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../AuthContext";
import { API_URL } from "../../config";

const useUpdateKey = () => {
  const [isLoaded, setIsLoaded] = useState(null);
  const [error, setError] = useState(null);

  const { logout } = useAuth();

  const updateKey = async data => {
    setIsLoaded(false);
    const token = localStorage.getItem("token"); // Получение токена из локального хранилища

    try {
      const response = await axios.post(
        `${API_URL}/keys_management/update_key.php`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Токен для авторизации
          },
        }
      );

      setIsLoaded(true);
      return response.data; // Возвращаем данные ответа
    } catch (error) {
      if (error.response?.status === 401) {
        logout(); // Если ошибка авторизации, разлогиниваем пользователя
      }

      setError(error.response?.data?.error || "Ein Fehler ist aufgetreten");
      setIsLoaded(true);
      throw error; // Пробрасываем ошибку для обработки выше
    }
  };

  return [updateKey, isLoaded, error];
};

export default useUpdateKey;
