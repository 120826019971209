import { Typography } from "@mui/material";
import React from "react";

function NoFoundText({ children, variant = "h3" }) {
	return (
		<Typography className="no-found-text" variant={variant}>
			{children}
		</Typography>
	);
}

export default NoFoundText;
