import { useState, useCallback } from "react";
import axios from "axios";
import { API_URL } from "../../config"; // Предположим, что этот файл содержит URL API
import { useAuth } from "../../AuthContext";

const useReturnKey = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const { logout } = useAuth();

	const returnKey = useCallback(
		async keyId => {
			setLoading(true);
			setError(null);
			try {
				const token = localStorage.getItem("token");

				const response = await axios.post(
					`${API_URL}/keys_management/return_key_app.php`,
					{ keyId },
					{
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);

				return response.data;
			} catch (err) {
				if (err.response?.status === 401) {
					logout();
				} else {
					setError(`Fehler: ${err.message}`);
				}
			} finally {
				setLoading(false);
			}
		},
		[logout]
	);

	return { returnKey, loading, error };
};

export default useReturnKey;
