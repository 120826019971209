import { useState } from "react";
import { API_URL } from "../../config";
import { useAuth } from "../../AuthContext";

const useDeleteUser = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const { logout } = useAuth();

	const deleteUser = async userId => {
		setLoading(true);
		const token = localStorage.getItem("token");

		if (!token) {
			setError("Token nicht gefunden. Bitte melden Sie sich an.");
			setLoading(false);
			return;
		}

		try {
			const response = await fetch(`${API_URL}/users/delete_user.php`, {
				method: "DELETE",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ id: userId }),
			});

			if (response.status === 401) {
				logout();
				return;
			}

			if (!response.ok) {
				throw new Error("Ein Fehler ist aufgetreten");
			}

			return userId; // Вернуть ID удаленного пользователя
		} catch (err) {
			setError(err.message || "Ein Fehler ist aufgetreten");
			throw err;
		} finally {
			setLoading(false);
		}
	};

	return { deleteUser, loading, error };
};

export default useDeleteUser;
