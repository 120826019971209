import { useState } from "react";
import axios from "axios";
import { API_URL } from "../config";
import { useAuth } from "../AuthContext";

const useAddObjekt = () => {
	const [isLoaded, setIsLoaded] = useState(null);
	const [error, setError] = useState(null);

	const { logout } = useAuth();

	const createNewObject = async data => {
		setIsLoaded(false);
		const token = localStorage.getItem("token"); // Извлечение токена из локального хранилища

		try {
			const response = await axios.post(`${API_URL}/add_new_objekt.php`, data, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`, // Добавление токена в заголовок
				},
			});

			// После успешного выполнения первого запроса, отправляем второй на add_initial_objekt.php
			const initialObjectResponse = await axios.post(
				`${API_URL}/add_initial_objekt.php`,
				data,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`, // Добавление токена в заголовок
					},
				}
			);

			setIsLoaded(true);

			return response.data;
		} catch (error) {
			if (error.response.status === 401) {
				logout();
			}
			setError(error.response?.data.error || "Fehler");
			setIsLoaded(true);
			throw error; // Пробрасываем ошибку для обработки в вызывающем коде
		}
	};

	return [createNewObject, isLoaded, error];
};

export default useAddObjekt;
