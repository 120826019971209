import { useEffect, useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import QrScanner from "qr-scanner";
import "./QrStyles.css"; // Для пользовательских стилей

const QrReader = ({
	scannedResult,
	setScannedResult,
	onScanSuccess,
	cameraBlocked,
	setCameraBlocked,
}) => {
	// QR States
	const scanner = useRef(null);
	const videoEl = useRef(null);
	const qrBoxEl = useRef(null);
	const [qrOn, setQrOn] = useState(true);

	// Result

	// Failure handler
	const onScanFail = err => {
		console.log(err);
	};

	// Initialize QR scanner
	useEffect(() => {
		if (videoEl?.current && !scanner.current) {
			scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
				onDecodeError: onScanFail,
				preferredCamera: "environment",
				highlightScanRegion: true,
				highlightCodeOutline: true,
				overlay: qrBoxEl?.current || undefined,
			});

			scanner?.current
				?.start()
				.then(() => setQrOn(true))
				.catch(err => {
					if (err) {
						setQrOn(false);
						setCameraBlocked(true); // Если ошибка, камера заблокирована
					}
				});
		}

		return () => {
			if (videoEl?.current) {
				scanner?.current?.stop();
			}
		};
	}, []);

	// Функция прокрутки страницы через 200ms
	const handleScroll = () => {
		setTimeout(() => {
			window.scrollBy({
				top: 300, // Прокручиваем страницу на 300 пикселей вниз
				behavior: "smooth", // Плавная прокрутка
			});
		}, 200); // Задержка в 200 миллисекунд
	};

	// Включаем сканирование и вызываем прокрутку
	useEffect(() => {
		if (qrOn) {
			handleScroll(); // Вызываем функцию прокрутки
		}
	}, [qrOn]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				backgroundColor: "#000000b0", // Прозрачный черный фон
				position: "relative",
				padding: 3,
				borderRadius: 2,
				boxShadow: 3,
			}}
		>
			<Typography variant="h5" color="white" sx={{ marginBottom: 3 }}>
				Scan QR Code
			</Typography>

			{/* Уведомление о блокировке камеры */}
			{cameraBlocked && (
				<Box
					sx={{
						padding: 2,
						backgroundColor: "#f44336", // Красный цвет для уведомления об ошибке
						color: "white",
						borderRadius: 2,
						boxShadow: 3,
						maxWidth: "80%",
						textAlign: "center",
					}}
				>
					<Typography variant="body2">
						Die Kamera ist blockiert oder nicht zugänglich. Bitte erlauben Sie
						den Zugriff auf die Kamera in den Browsereinstellungen und laden Sie
						die Seite neu.
					</Typography>
				</Box>
			)}

			<div
				className="qr-reader"
				style={{ position: "relative", width: "100%", maxWidth: "400px" }}
			>
				{/* Video Element */}
				<video
					ref={videoEl}
					style={{ width: "100%", height: "auto", borderRadius: 8 }}
				></video>

				{/* QR Box Overlay */}
				<div
					ref={qrBoxEl}
					className="qr-box"
					style={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: "100%",
						height: "250px",
						border: "2px solid yellow",
						borderRadius: 8,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				></div>
			</div>

			{/* Display Scanned Result */}
			{scannedResult && (
				<Typography
					variant="body1"
					sx={{
						position: "absolute",
						bottom: 30,
						left: 0,
						right: 0,
						textAlign: "center",
						zIndex: 10,
						color: "white",
					}}
				>
					Scanned Result: {scannedResult}
				</Typography>
			)}
		</Box>
	);
};

export default QrReader;
