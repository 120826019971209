import React from "react";
import ReactDOM from "react-dom/client";

import "./scss/app.scss";

import App from "./App";

import { HashRouter } from "react-router-dom";

import store from "./redux/store/store";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Provider store={store}>
		<HashRouter>
			<App />
		</HashRouter>
	</Provider>
);
