import React from "react";

import DraggableItem from "../DragAndDrop/DraggableItem";

import getColor from "../../../hooks/useGetColor.js";

function Addresses({ items, isLoadedAssignment, handleOpenModal }) {
	return (
		<div className="addresses">
			<div className="addresses__content">
				<ul className="addresses__list">
					{
						<div className="addresses__city">
							<div className="addresses__addresses-container">
								{items.map((item, idx) => {
									// Если смогу реализовать рассположение городов с заголовком
									// const title =
									// 	item.city.charAt(0).toUpperCase() + item.city.slice(1);

									const streetStyle = {
										backgroundColor: getColor(item.city).backgroundColor,
										color: getColor(item.city).color,
									};

									return (
										<DraggableItem
											className="addresses__street"
											key={item.id}
											id={item.id}
											keyObjekte={item.keyObjekte}
											rhythm={item.rhythm}
											team={item.team}
											text={item.address}
											style={streetStyle}
											isLoadedAssignment={isLoadedAssignment}
											handleOpenModal={handleOpenModal}
										/>
									);
								})}
							</div>
						</div>
					}
				</ul>
			</div>
		</div>
	);
}

export default Addresses;
