import React from "react";

import ErrorIcon from "@mui/icons-material/Error";

import { Typography } from "@mui/material";

function ErrorAddresse({ err, ...props }) {
	return (
		<div className="scheduling-err">
			<div className="scheduling-err__content">
				<Typography {...props} variant="h3" className="scheduling-err__error">
					{err}&nbsp;&nbsp;
					<ErrorIcon sx={{ fontSize: 40 }} />
				</Typography>
			</div>
		</div>
	);
}

export default ErrorAddresse;
