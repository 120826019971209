import { Typography } from "@mui/material";
import { PageTitleContext } from "../../PageTitleContext";
import { useContext, useEffect } from "react";
import TableSchlussel from "./TableSchlussel";

function SchlusselVerwalten() {
	// Изменить название заголовка страницы
	const { setTitle } = useContext(PageTitleContext);
	useEffect(() => {
		setTitle("Schlüssel Verwalten");
	}, [setTitle]);

	return (
		<div className="deleteObjekt">
			<div className="deleteObjekt__content">
				<Typography
					variant="h3"
					sx={{ marginTop: "30px", marginBottom: "50px" }}
				>
					Hier können Sie Schlüssel-Status beobachten.
				</Typography>
				<TableSchlussel />
			</div>
		</div>
	);
}

export default SchlusselVerwalten;
