import React from "react";
import { useDrop } from "react-dnd";

const DroppableArea = ({ onItemDropped, children, target, className }) => {
	const [{ isOver, canDrop }, drop] = useDrop({
		accept: "ITEM",
		drop: item => {
			onItemDropped(item, target);
		},
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop(),
		}),
	});

	return (
		<div
			ref={drop}
			style={{
				backgroundColor: isOver && canDrop ? "lightblue" : "transparent",
				padding: "16px",
				paddingTop: "10px",
				marginTop: "10px",
				minHeight: "100vh",
				borderRadius: "10px",
				flex: 1,
			}}
			className={className}
		>
			{children}
		</div>
	);
};

export default DroppableArea;
