import React, { useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
	Box,
	CircularProgress,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

function ConfirmDialog({
	openDialog,
	scannedResult,
	handleCloseDialog,
	handleConfirm,
	operation, // Операция (get/return)
}) {
	const [isSubmitting, setIsSubmitting] = useState(false); // Состояние для управления загрузкой

	const id = scannedResult?.split("-")[1]; // Извлекаем ID объекта из QR-кода

	// Формируем сообщение в зависимости от операции с подчеркиванием
	const operationMessage =
		operation === "return" ? (
			<>
				Möchten Sie den Schlüssel mit der ID <strong>{id}</strong> wirklich{" "}
				<span style={{ textDecoration: "underline" }}>zurückgeben</span>?
			</>
		) : (
			<>
				Möchten Sie den Schlüssel mit der ID <strong>{id}</strong> wirklich{" "}
				<span style={{ textDecoration: "underline" }}>nehmen</span>?
			</>
		);

	// Выбираем иконку и цвет в зависимости от операции
	const operationIcon =
		operation === "return" ? (
			<ArrowUpwardIcon color="success" sx={{ fontSize: 40, mr: 2 }} />
		) : (
			<ArrowDownwardIcon color="error" sx={{ fontSize: 40, mr: 2 }} />
		);

	// Обработчик подтверждения
	const handleSubmit = async () => {
		setIsSubmitting(true); // Устанавливаем состояние загрузки
		try {
			await handleConfirm(); // Вызываем переданную функцию подтверждения
		} finally {
			setIsSubmitting(false); // Сбрасываем состояние загрузки
		}
	};

	return (
		<Dialog open={openDialog} onClose={!isSubmitting ? handleCloseDialog : null}>
			<DialogTitle>QR Code Bestätigen</DialogTitle>
			<DialogContent>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					{operationIcon}
					<Typography variant="body1">{operationMessage}</Typography>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleCloseDialog}
					color="secondary"
					disabled={isSubmitting} // Блокируем кнопку во время загрузки
				>
					Noch einmal scannen
				</Button>
				<Button
					onClick={handleSubmit}
					color="success"
					disabled={isSubmitting} // Блокируем кнопку во время загрузки
					endIcon={isSubmitting ? <CircularProgress color="secondary" size={20} sx={{ ml: 0.5 }} /> : null} // Иконка загрузки
				>
					{isSubmitting ? "Wird gesendet..." : "Bestätigen"}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default ConfirmDialog;
