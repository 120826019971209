import { useState, useEffect } from "react";
import { API_URL } from "../../config";
import { useAuth } from "../../AuthContext";

const useGetUsers = () => {
	const [users, setUsers] = useState([]); // Состояние для списка пользователей
	const [loading, setLoading] = useState(true); // Состояние загрузки
	const [error, setError] = useState(null); // Состояние ошибки
	const { logout } = useAuth();

	useEffect(() => {
		// Функция для получения данных пользователей
		const fetchUsers = async () => {
			setLoading(true); // Начинаем загрузку
			const token = localStorage.getItem("token"); // Извлекаем JWT-токен из localStorage

			if (!token) {
				setError("Token nicht gefunden. Bitte melden Sie sich an."); // Сообщение об ошибке на немецком
				setLoading(false);
				return;
			}

			try {
				// Отправляем запрос с JWT-токеном в заголовках
				const response = await fetch(`${API_URL}/users/get_all_users.php`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json", // Указываем тип контента
						Authorization: `Bearer ${token}`, // Добавляем токен в заголовки запроса
					},
				});

				if (response.status === 401) {
					logout();
				}

				if (!response.ok) {
					throw new Error(
						"Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut."
					); // Ошибка на сервере
				}

				const data = await response.json(); // Разбираем ответ как JSON

				if (data.error) {
					throw new Error(data.error); // Ошибка в данных
				}

				setUsers(data); // Сохраняем данные в состояние
			} catch (err) {
				setError("Ein Fehler ist aufgetreten: " + err.message); // Обработка ошибок
			} finally {
				setLoading(false); // Завершаем загрузку
			}
		};

		fetchUsers(); // Вызываем функцию для получения пользователей
	}, []); // Эффект срабатывает только один раз при монтировании компонента

	return { users, loading, error, setUsers }; // Возвращаем данные для использования в компонентах
};

export default useGetUsers;
