import { useCallback } from "react";
import axios from "axios";
import { API_URL } from "../config";
import { useAuth } from "../AuthContext";

const useDeleteObjektsAPI = () => {
	const { logout } = useAuth();

	const deleteObjekts = useCallback(
		async ids => {
			try {
				const token = localStorage.getItem("token");

				const response = await axios.post(
					`${API_URL}/delete_objekts.php`,
					{ ids },
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
					}
				);

				// Возвращаем данные из ответа сервера
				return response.data;
			} catch (error) {
				if (error.response && error.response.status === 401) {
					logout(); // Вызовем logout() в случае ошибки авторизации
					return false;
				}

				throw error; // Пробрасываем ошибку дальше, чтобы её можно было обработать в вызывающем коде
			}
		},
		[logout]
	);

	return deleteObjekts;
};

export default useDeleteObjektsAPI;
