import { Typography } from "@mui/material";
import { PageTitleContext } from "../../PageTitleContext";
import { useContext, useEffect } from "react";
import Form from "./Form";

function SchlusselAdd() {
	// Изменить название заголовка страницы
	const { setTitle } = useContext(PageTitleContext);
	useEffect(() => {
		setTitle("Schlüssel hinzufügen");
	}, [setTitle]);

	return (
		<div className="deleteObjekt">
			<div className="deleteObjekt__content">
				<Typography
					variant="h3"
					sx={{ marginTop: "30px", marginBottom: "50px" }}
				>
					Hier können Sie Schlüssel hinzufügen.
				</Typography>
				<Form />
			</div>
		</div>
	);
}

export default SchlusselAdd;
