// Action types
export const ADD_ADDRESS = "ADD_ADDRESS";
export const REMOVE_ADDRESS = "REMOVE_ADDRESS";
export const CHANGE_WEEK = "CHANGE_WEEK";
export const CHANGE_DAY = "CHANGE_DAY";

// Action creators
export const addAddress = (address, week, day) => ({
	type: ADD_ADDRESS,
	payload: { address, week, day },
});

export const removeAddress = (addressId, week, day) => ({
	type: REMOVE_ADDRESS,
	payload: { addressId, week, day },
});

export const changeWeek = week => ({
	type: CHANGE_WEEK,
	payload: week,
});

export const changeDay = day => ({
	type: CHANGE_DAY,
	payload: day,
});
