import React, { useState } from "react";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	CircularProgress,
	Typography,
	Snackbar,
	Alert,
	IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CameraAltIcon from "@mui/icons-material/CameraAlt"; // Иконка камеры для изменения фото
import useDeleteObjektsAPI from "../../api/keysManagment/useDeleteObjektsAPI";

function DialogModal({
	open,
	handleClose,
	loading,
	activeObject,
	handleConfirmDelete,
}) {
	const [openSnackbar, setOpenSnackbar] = useState(false); // Для отображения Snackbar
	const [snackbarMessage, setSnackbarMessage] = useState(""); // Сообщение в Snackbar
	const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // Уровень важности сообщения
	const [photo, setPhoto] = useState(null); // Состояние для хранения фото
	const [photoPreview, setPhotoPreview] = useState(null); // Для отображения превью фото

	// Используем хук для удаления объектов
	const deleteObjektsAPI = useDeleteObjektsAPI();

	// Функция для отправки запроса на удаление
	const handleDelete = async () => {
		try {
			// Создаем массив ID удаляемых объектов
			const idsToDelete = activeObject.map(item => item.id);

			// Вызываем API для удаления
			const result = await deleteObjektsAPI(idsToDelete);

			// Если удаление прошло успешно
			if (result) {
				setSnackbarMessage("Schlüssel erfolgreich gelöscht!");
				setSnackbarSeverity("success");
				handleConfirmDelete();
			} else {
				setSnackbarMessage("Fehler beim Löschen der Schlüssel.");
				setSnackbarSeverity("error");
			}
		} catch (error) {
			// В случае ошибки
			setSnackbarMessage("Es gab ein Problem beim Löschen.");
			setSnackbarSeverity("error");
		} finally {
			// Закрытие модального окна после операции
			handleClose();
			setOpenSnackbar(true); // Показываем Snackbar
		}
	};

	// Закрытие Snackbar
	const handleCloseSnackbar = () => {
		setOpenSnackbar(false);
	};

	// Функция для загрузки нового фото
	const handlePhotoChange = event => {
		const file = event.target.files[0];
		if (file) {
			setPhoto(file);
			setPhotoPreview(URL.createObjectURL(file)); // Отображаем превью
		}
	};

	return (
		<>
			<Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
				<DialogTitle>Schlüssel Verlauf: Löschung bestätigen</DialogTitle>
				<DialogContent>
					{loading ? (
						<div style={{ textAlign: "center", padding: "20px 0" }}>
							<CircularProgress color="secondary" />
						</div>
					) : (
						<>
							<Typography
								variant="body1"
								gutterBottom
								style={{ marginBottom: "20px" }}
							>
								Sind Sie sicher, dass Sie die folgenden Schlüsselverläufe
								löschen möchten?
							</Typography>
							<div className="deleteObjekt__body-modal">
								{activeObject.map(item => (
									<div
										key={item.id}
										data-id={item.id}
										style={{ backgroundColor: "#000", color: "#fff" }}
										className="deleteObjekt__item _modal"
									>
										{item.key_address}
									</div>
								))}
							</div>
						</>
					)}
				</DialogContent>
				<DialogActions sx={{ paddingBottom: "16px", paddingRight: "16px" }}>
					<Button onClick={handleClose} variant="primary">
						Abbrechen
					</Button>
					<Button
						onClick={handleDelete} // вызываем функцию удаления
						color="error"
						startIcon={<DeleteIcon />}
						variant="contained"
					>
						Löschen
					</Button>
				</DialogActions>
			</Dialog>

			{/* Snackbar для отображения сообщений */}
			<Snackbar
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={handleCloseSnackbar}
			>
				<Alert
					onClose={handleCloseSnackbar}
					severity={snackbarSeverity} // Уровень важности сообщения
					variant="filled"
				>
					{snackbarMessage} {/* Сообщение для пользователя */}
				</Alert>
			</Snackbar>
		</>
	);
}

export default DialogModal;
