const getColor = key => {
	switch (key) {
		case "hagen":
			return { backgroundColor: "yellow", color: "#000" };
		case "witten":
			return { backgroundColor: "#DDDDDD", color: "#000" };
		case "wetter":
			return { backgroundColor: "#6699ff", color: "#000" };
		case "bochum":
			return { backgroundColor: "#e78f08", color: "#000" };
		case "dortmund":
			return { backgroundColor: "#0088cc", color: "#000" };
		case "ennepetal":
			return { backgroundColor: "#f1f1f1", color: "#000" };
		case "essen":
			return { backgroundColor: "#b2b2b2", color: "#000" };
		case "hattingen":
			return { backgroundColor: "#ffe600", color: "#000" };
		case "witten-annen":
			return { backgroundColor: "#333333", color: "#fff" };
		case "witten-bommern":
			return { backgroundColor: "#124D77", color: "#fff" };
		case "witten-herbede":
			return { backgroundColor: "#626262", color: "#fff" };
		case "witten-heven":
			return { backgroundColor: "#887DC2", color: "#000" };
		case "wittne-stockum":
			return { backgroundColor: "#FFD700", color: "#000" }; // Gold color
		case "witten-rüdinghausen":
			return { backgroundColor: "#FF6347", color: "#000" }; // Tomato color
		case "witten-stockum":
			return { backgroundColor: "#8A2BE2", color: "#fff" }; // Blue violet color
		case "herdecke":
			return { backgroundColor: "#32CD32", color: "#000" }; // Lime green color
		case "gelsenkirchen":
			return { backgroundColor: "#FF4500", color: "#000" }; // Orange red color
		case "menden":
			return { backgroundColor: "#00CED1", color: "#000" }; // Dark turquoise color
		case "herne":
			return { backgroundColor: "#FFB6C1", color: "#000" }; // Dark turquoise color
		case "recklinghausen":
			return { backgroundColor: "#87CEEB", color: "#000" }; // Dark turquoise color
		default:
			return { backgroundColor: "#979797", color: "#fff" }; // Default colors
	}
};

export default getColor;
