import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

function LoadingInfo({ text = "Daten laden..." }) {
	return (
		<Box
			display="flex"
			flexDirection="column"
			justifyContent="center"
			gap="32px"
			alignItems="center"
			height="100%"
			width="100%"
			bgcolor="transparent"
		>
			<CircularProgress color="secondary" />
			<Typography variant="h5">{text}</Typography>
		</Box>
	);
}

export default LoadingInfo;
