import { useState } from "react";
import axios from "axios";
import { useAuth } from "../../AuthContext";
import { API_URL } from "../../config";

const useAddKey = () => {
	const [isLoaded, setIsLoaded] = useState(null);
	const [error, setError] = useState(null);

	const { logout } = useAuth();

	const addNewObject = async data => {
		setIsLoaded(false);
		const token = localStorage.getItem("token"); // Извлечение токена из локального хранилища

		try {
			const response = await axios.post(
				`${API_URL}/keys_management/add_new_key.php`,
				data,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`, // Добавление токена в заголовок
					},
				}
			);

			setIsLoaded(true);
			return response.data;
		} catch (error) {
			if (error.response.status === 401) {
				logout();
			}
			console.log(error);

			setError(error.response?.data.error || "Fehler");
			setIsLoaded(true);
			throw error.response.data.error; // Пробрасываем ошибку для обработки в вызывающем коде
		}
	};

	return [addNewObject, isLoaded, error];
};

export default useAddKey;
