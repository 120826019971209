import React from "react";

import ErrorAddresse from "./Error/ErrorAddresse";
import Addresses from "./Addresses/Addresses";
import WeekCalendar from "../../components/WeekCalendar/WeekCalendar";
import { CircularProgress } from "@mui/material";
import DroppableArea from "./DragAndDrop/DroppableArea";

function LeftSide({
	isLoaded,
	error,
	currentDate,
	setCurrentDate,
	setLeftItems,
	setRightItems,
	leftItems,
	handleDrop,
	team,
	setTeam,
	isLoadedAssignment,
	handleOpenModal,
}) {
	return (
		<div className="panel scheduling__split-panel panel2">
			<WeekCalendar
				team={team}
				setTeam={setTeam}
				currentDate={currentDate}
				setCurrentDate={setCurrentDate}
				setLeftItems={setLeftItems}
				setRightItems={setRightItems}
				isLoaded={isLoaded}
			/>
			{isLoaded ? (
				error ? (
					<ErrorAddresse err={error} />
				) : (
					<DroppableArea onItemDropped={handleDrop} target="left">
						<Addresses
							isLoadedAssignment={isLoadedAssignment}
							items={leftItems}
							handleDrop={handleDrop}
							handleOpenModal={handleOpenModal}
						/>
					</DroppableArea>
				)
			) : (
				<div className="scheduling__content-err">
					<CircularProgress color="secondary" />
				</div>
			)}
		</div>
	);
}

export default LeftSide;
