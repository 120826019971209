import { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import "react-pro-sidebar/dist/css/styles.css";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { CSSTransition } from "react-transition-group";

import { Box, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { tokens } from "../../theme";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import KeyIcon from "@mui/icons-material/Key";
import HistoryIcon from "@mui/icons-material/History";
import AddHomeIcon from "@mui/icons-material/AddHome";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import CloseIcon from "@mui/icons-material/Close";
import AccountBoxIcon from "@mui/icons-material/AccountBox";


import ProfilImg from "../../img/profile-img.webp";
import { useAuth } from "../../AuthContext";
import { DoNotDisturbOnTotalSilenceOutlined } from "@mui/icons-material";

const Item = ({ title, to, icon, selected, setSelected }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	return (
		<MenuItem
			active={selected === title}
			style={{
				color: colors.grey[100],
			}}
			onClick={() => setSelected(title)}
			icon={icon}
		>
			<Typography>{title}</Typography>
			<Link to={to} />
		</MenuItem>
	);
};

const Sidebar = ({ isCollapsed, setIsCollapsed }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const [selected, setSelected] = useState("Profile");
	const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Определяем, является ли устройство мобильным

	const { isAuthenticated, loadingLogin, user } = useAuth(); // Используем хук для получения статуса аутентификации

	useEffect(() => {
		if (isAuthenticated) {
			setSelected("Profile");
		}
	}, [isAuthenticated]);

	return (
		<Box
			sx={{
				"& .pro-sidebar-inner": {
					background: `${colors.primary[400]} !important`,
				},
				"& .pro-icon-wrapper": {
					backgroundColor: "transparent !important",
				},
				"& .pro-inner-item": {
					padding: "5px 35px 5px 20px !important",
				},
				"& .pro-inner-item:hover": {
					color: "#868dfb !important",
				},
				"& .pro-menu-item.active": {
					color: "#6870fa !important",
				},
				// Стили для мобильного устройства
				transform: isMobile
					? isCollapsed
						? "translateX(-100%)"
						: "translateX(0)"
					: "none", // Перемещение влево при закрытии на мобильных
				transition: isMobile ? "transform 0.3s ease" : "none", // Анимация для плавного движения только на мобильных
				position: isMobile ? "fixed" : "relative", // Фиксированное положение на экране только на мобильных
				left: isMobile ? 0 : undefined, // Прилегание к левой стороне экрана только на мобильных
				top: isMobile ? 0 : undefined, // Прилегание к верхней части экрана только на мобильных
				height: isMobile ? "100vh" : "auto", // Полная высота экрана только на мобильных
				zIndex: 1200, // Приоритет отображения
				display: { xs: "block", md: "block" }, // Отображение на всех устройствах
			}}
		>
			<ProSidebar collapsed={isCollapsed}>
				<Menu iconShape="square">
					{/* Burger Icon */}
					{isMobile ? (
						<MenuItem
							onClick={() => setIsCollapsed(!isCollapsed)}
							icon={<CloseIcon />}
							style={{
								margin: "5px 0 16px 0",
								color: colors.grey[100],
							}}
						></MenuItem>
					) : (
						<MenuItem
							onClick={() => setIsCollapsed(!isCollapsed)}
							icon={
								isCollapsed ? (
									<MenuOutlinedIcon sx={{ fontSize: 30 }} />
								) : undefined
							}
							style={{
								margin: "10px 0 20px 0",
								color: colors.grey[100],
							}}
						>
							{!isCollapsed && (
								<Box
									style={{
										display: "flex",
										alignItems: "flex-start",
										justifyContent: "center",
									}}
									ml="10px"
								>
									<IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
										<MenuOutlinedIcon sx={{ fontSize: 30 }} />
									</IconButton>
								</Box>
							)}
						</MenuItem>
					)}

					{loadingLogin ? (
						""
					) : (
						<CSSTransition
							in={!isCollapsed}
							timeout={300} // время анимации
							classNames="fade" // имя классов для анимации
							unmountOnExit // удаляет элемент из DOM, когда не отображается
						>
							<Box mb="25px">
								<Box display="flex" justifyContent="center" alignItems="center">
									<img
										alt="profile-user"
										width="50px"
										height="50px"
										src={isAuthenticated ? user.photo_path : ProfilImg}
										style={{
											borderRadius: "50%",
											objectFit: "cover",
										}}
									/>
								</Box>
								<Box textAlign="center">
									<Typography
										variant="h4"
										color={colors.grey[100]}
										fontWeight="bold"
										sx={{ m: "10px 0 0 0" }}
									>
										{isAuthenticated ? user.username : ""}
									</Typography>
									<Typography variant="h6" color={colors.greenAccent[500]}>
										{isAuthenticated ? user.role : ""}
									</Typography>
								</Box>
							</Box>
						</CSSTransition>
					)}
					{/* Если нужно будет, реализую вход в аккаунт и тут будет фотография и описание человека */}

					{loadingLogin ? (
						""
					) : isAuthenticated ? (
						<Box
							paddingLeft={isCollapsed ? undefined : "10%"}
							display="flex"
							flexDirection="column"
							gap="15px"
							onClick={() => setIsCollapsed(true)}
						>

							{user?.role === "admin" && (
								<>
									<Item
										title="Profile"
										to="/"
										icon={<AccountBoxIcon sx={{ fontSize: 25 }} />}
										selected={selected}
										setSelected={setSelected}
									/>
									<Item
										title="Objekte plannen"
										to="/planenObjekt"
										icon={<CalendarMonthIcon sx={{ fontSize: 25 }} />}
										selected={selected}
										setSelected={setSelected}
									/>

									<Item
										title="Objekt hinzufügen"
										to="/addObjekt"
										icon={<AddLocationAltIcon sx={{ fontSize: 25 }} />}
										selected={selected}
										setSelected={setSelected}
									/>
									<Item
										title="Objekt löschen"
										to="/deleteObjekt"
										icon={<DeleteIcon sx={{ fontSize: 25 }} />}
										selected={selected}
										setSelected={setSelected}
									>
										Data
									</Item>
									<Item
										title="Schlüssel verwalten"
										to="/schlusselVerwalten"
										icon={<KeyIcon sx={{ fontSize: 25 }} />}
										selected={selected}
										setSelected={setSelected}
									></Item>

									<Item
										title="Schlüssel Verlauf"
										to="/schlusselVerlauf"
										icon={<HistoryIcon sx={{ fontSize: 25 }} />}
										selected={selected}
										setSelected={setSelected}
									></Item>
									<Item
										title="Schlüssel hinzufügen"
										to="/addSchlussel"
										icon={<AddHomeIcon sx={{ fontSize: 25 }} />}
										selected={selected}
										setSelected={setSelected}
									></Item>
									<Item
										title="Mitarbeiter verwalten"
										to="/usersVerwalten"
										icon={<PeopleAltIcon sx={{ fontSize: 25 }} />}
										selected={selected}
										setSelected={setSelected}
									></Item>
									<Item
										title="Benutzer erstellen"
										to="/addUser"
										icon={<PersonAddAltIcon sx={{ fontSize: 25 }} />}
										selected={selected}
										setSelected={setSelected}
									></Item>
								</>

							)}

							{user?.role === "worker" && (
								<>
									<Item
										title="Profile"
										to="/"
										icon={<AccountBoxIcon sx={{ fontSize: 25 }} />}
										selected={selected}
										setSelected={setSelected}
									/>
									<Item
										title="Schlüssel"
										to="/schlussel"
										icon={<KeyIcon sx={{ fontSize: 25 }} />}
										selected={selected}
										setSelected={setSelected}
									/>

								</>

							)}


						</Box>
					) : (
						""
					)
					}
				</Menu >
			</ProSidebar >
		</Box >
	);
};

export default Sidebar;
