import React from "react";
import Button from "@mui/material/Button";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

const PDFButton = ({ onClick, children }) => {
	return (
		<Button
			color={"secondary"}
			variant="contained"
			startIcon={<SaveAltIcon />}
			onClick={onClick}
			style={{ display: "flex", alignItems: "flex-start" }}
		>
			{children}
		</Button>
	);
};

export default PDFButton;
