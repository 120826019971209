import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { API_URL } from "../config";
import { useAuth } from "../AuthContext";

const useGetAllObejkte = () => {
	const [data, setData] = useState(null); // Состояние для хранения данных
	const [error, setError] = useState(null); // Состояние для хранения ошибок
	const [loading, setLoading] = useState(true); // Состояние для отслеживания загрузки
	const { logout } = useAuth(); // Получение функции выхода из AuthContext

	// Функция для выполнения запроса, обернута в useCallback
	const fetchAssignments = useCallback(async () => {
		try {
			const token = localStorage.getItem("token"); // Извлечение токена из локального хранилища

			// URL вашего скрипта
			const response = await axios.get(`${API_URL}/get_initial.php`, {
				headers: {
					Authorization: `Bearer ${token}`, // Добавление токена в заголовок
				},
			});

			// Предполагаем, что сервер возвращает объект с ключом 'unassignments', который содержит JSON
			const unassignmentsJson = response.data.unassignments;

			// Парсим JSON из строки
			let unassignments = JSON.parse(unassignmentsJson);
			unassignments = unassignments.sort((a, b) => {
				if (a.city < b.city) return -1;
				if (a.city > b.city) return 1;
				return 0;
			});

			setData(unassignments); // Устанавливаем распарсенные данные в состояние
		} catch (err) {
			if (err.response?.status === 401) {
				logout(); // Выход при ошибке авторизации
				return;
			}
			setError(err.message); // Устанавливаем сообщение об ошибке
		} finally {
			setLoading(false); // Завершаем загрузку
		}
	}, [logout]); // Добавляем `logout` в зависимости, чтобы сброс происходил корректно

	useEffect(() => {
		fetchAssignments(); // Запуск функции загрузки данных при монтировании компонента
	}, [fetchAssignments]); // Добавляем fetchAssignments в зависимости

	return [data, setData, error, loading];
};

export default useGetAllObejkte;
