import { useContext, useEffect } from "react";

import { Typography } from "@mui/material";
import { PageTitleContext } from "../../PageTitleContext";
import FormUser from "./FormUser";

function UserAdd() {
	// Изменить название заголовка страницы
	const { setTitle } = useContext(PageTitleContext);
	useEffect(() => {
		setTitle("Benutzer erstellen");
	}, [setTitle]);

	return (
		<div className="deleteObjekt">
			<div className="deleteObjekt__content">
				<Typography
					variant="h3"
					sx={{ marginTop: "30px", marginBottom: "50px" }}
				>
					Hier können Sie Benutzer erstellen.
				</Typography>
				<FormUser />
			</div>
		</div>
	);
}

export default UserAdd;
