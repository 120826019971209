import React, { useEffect, useState } from "react";
import {
	Modal,
	Paper,
	TextField,
	IconButton,
	Button,
	Typography,
	FormControl,
	Select,
	MenuItem,
	InputLabel,
	Box,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";

const EditUserModal = ({ open, user, onClose, onSave, onChange }) => {
	// Локальное состояние для выбранного изображения
	const [selectedImage, setSelectedImage] = useState(null);

	// Обработчик выбора изображения
	const handleImageChange = event => {
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];
			setSelectedImage(file);
			// Немедленно обновляем превью в UI
			onChange({ ...user, photo_path: URL.createObjectURL(file) });
		}
	};

	// Обработчик отправки данных
	const handleSave = () => {
		onSave({
			...user,
			image: selectedImage, // Передаем выбранное изображение
		});
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Paper
				style={{
					padding: "40px",
					margin: "auto",
					maxWidth: 500,
					marginTop: "10%",
					borderRadius: "10px",
				}}
			>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Typography variant="h5">
						Konto von {user?.username} bearbeiten
					</Typography>
					<IconButton onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</Box>

				{/* Картинка пользователя */}
				<Box
					sx={{
						position: "relative",
						width: "120px",
						height: "120px",
						margin: "20px auto",
						borderRadius: "50%",
						overflow: "hidden",
						border: "2px solid #ccc",
						cursor: "pointer",
					}}
					onClick={() => document.getElementById("image-upload").click()}
				>
					<img
						src={user?.photo_path} // Показываем картинку пользователя
						alt={user?.username}
						style={{ width: "100%", height: "100%", objectFit: "cover" }}
					/>
					<Box
						sx={{
							position: "absolute",
							top: 0,
							left: 0,
							width: "100%",
							height: "100%",
							backgroundColor: "rgba(0, 0, 0, 0.5)",
							color: "white",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							opacity: 0,
							transition: "opacity 0.3s",
							"&:hover": {
								opacity: 1,
							},
						}}
					>
						<EditIcon />
					</Box>
				</Box>

				{/* Поле для выбора файла */}
				<input
					id="image-upload"
					type="file"
					accept="image/*"
					style={{ display: "none" }}
					onChange={handleImageChange}
				/>

				<TextField
					label="Benutzername"
					fullWidth
					margin="normal"
					value={user?.username || ""}
					onChange={e => onChange({ ...user, username: e.target.value })}
				/>
				<TextField
					label="Passwort"
					fullWidth
					margin="normal"
					value={user?.password || ""}
					onChange={e => onChange({ ...user, password: e.target.value })}
				/>
				<FormControl fullWidth margin="normal">
					<InputLabel>Rolle</InputLabel>
					<Select
						value={user?.role || ""}
						onChange={e => onChange({ ...user, role: e.target.value })}
					>
						<MenuItem value="admin">Admin</MenuItem>
						<MenuItem value="worker">Mitarbeiter</MenuItem>
					</Select>
				</FormControl>

				<Button
					variant="contained"
					color="success"
					onClick={handleSave}
					style={{ marginTop: "20px", width: "100%" }}
				>
					Speichern
				</Button>
			</Paper>
		</Modal>
	);
};

export default EditUserModal;
