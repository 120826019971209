import { useState } from "react";
import { API_URL } from "../../config";
import { useAuth } from "../../AuthContext";

const useUpdateUser = () => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const { logout } = useAuth();

	const saveUser = async updatedUser => {
		setLoading(true);
		const token = localStorage.getItem("token");

		if (!token) {
			setError("Token nicht gefunden. Bitte melden Sie sich an.");
			setLoading(false);
			return;
		}

		// Формируем данные для отправки
		const formData = new FormData();
		formData.append("id", updatedUser.id);
		formData.append("username", updatedUser.username);
		formData.append("role", updatedUser.role);
		if (updatedUser.password) {
			formData.append("password", updatedUser.password);
		}

		console.log(updatedUser);

		if (updatedUser.image) {
			formData.append("photo", updatedUser.image); // Добавляем файл изображения
		}

		try {
			const response = await fetch(`${API_URL}/users/update_user.php`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`, // Авторизация
				},
				body: formData, // Отправляем FormData
			});

			if (response.status === 409) {
				throw new Error(
					"Benutzername mit diesem Benutzername bereits vergeben"
				);
			}

			if (response.status === 401) {
				logout();
				return;
			}

			if (!response.ok) {
				throw new Error("Ein Fehler ist aufgetreten");
			}

			const data = await response.json();
			return data; // Вернуть обновленные данные пользователя
		} catch (err) {
			setError(err.message || "Ein Fehler ist aufgetreten");
			throw err;
		} finally {
			setLoading(false);
		}
	};

	return { saveUser, loading, error };
};

export default useUpdateUser;
