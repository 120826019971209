import * as React from "react";
import { InputLabel, Select } from "@mui/material";
import { MenuItem } from "@mui/material";

const styles = {
	width: "150px", // Ширина селекта
	borderRadius: "4px", // Закругленные углы
	border: "1px solid #ced4da", // Граница
	padding: "2px", // Отступы
};

export default function TeamsSelect({ team, handleChange }) {
	return (
		<div>
			<InputLabel>Touren</InputLabel>
			<Select
				value={team}
				label="Touren"
				defaultValue={team}
				onChange={handleChange}
				sx={styles} // Применяем стили к селекту
			>
				<MenuItem value={"Alle Touren"}>Alle Touren</MenuItem>
				<MenuItem value={"Team1"}>Tour 1.</MenuItem>
				<MenuItem value={"Team2"}>Tour 2.</MenuItem>
				<MenuItem value={"Team3"}>Tour 3.</MenuItem>
				<MenuItem value={"Team4"}>Tour 4.</MenuItem>
				<MenuItem value={"Team5"}>Tour 5.</MenuItem>
				<MenuItem value={"Team6"}>Tour 6.</MenuItem>
				<MenuItem value={"Team7"}>Tour 7.</MenuItem>
				<MenuItem value={"Team8"}>Tour 8.</MenuItem>
				<MenuItem value={"Team9"}>Tour 9.</MenuItem>
				<MenuItem value={"Team10"}>Tour 10.</MenuItem>
				<MenuItem value={"Team11"}>Tour 11.</MenuItem>
				<MenuItem value={"Team12"}>Tour 12.</MenuItem>
				<MenuItem value={"Team13"}>Tour 13.</MenuItem>
				<MenuItem value={"Team14"}>Tour 14.</MenuItem>
				<MenuItem value={"Team15"}>Tour 15.</MenuItem>
				<MenuItem value={"Team16"}>Tour 16.</MenuItem>
				<MenuItem value={"Team17"}>Tour 17.</MenuItem>
				<MenuItem value={"Team18"}>Tour 18.</MenuItem>
				<MenuItem value={"Team19"}>Tour 19.</MenuItem>
				<MenuItem value={"Team20"}>Tour 20.</MenuItem>
			</Select>
		</div>
	);
}
