import { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../config";
import { useAuth } from "../AuthContext";

const useGetAssignedAddressesAPI = (week, weekday, team) => {
	const [data, setData] = useState([]);
	const [isLoaded, setIsLoaded] = useState(false);
	const [error, setError] = useState(null);
	const { logout } = useAuth();

	useEffect(() => {
		const fetchAssignedAddresses = async () => {
			setIsLoaded(false);
			setError(null);

			try {
				const token = localStorage.getItem("token"); // Извлечение токена из локального хранилища

				const response = await axios.get(
					`${API_URL}/get_assigned_addresses.php`,
					{
						params: {
							week: week,
						},
						headers: {
							Authorization: `Bearer ${token}`, // Добавление токена в заголовок
						},
					}
				);

				const data = response.data.data;

				if (response.status === 401) {
					logout();
				}

				if (response.status === 200) {
					setData(JSON.parse(data[0].assignments));
				} else {
					setError("Ошибка при загрузке назначенных адресов");
				}
			} catch (error) {
				if (error?.response?.status === 401) {
					logout();
				}

				setError("Что-то пошло не так");
			} finally {
				setIsLoaded(true);
			}
		};

		if (week) {
			fetchAssignedAddresses();
		}
	}, [week, weekday, team]);

	return { data, isLoaded, error };
};

export default useGetAssignedAddressesAPI;
