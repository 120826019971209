import React from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TableSortLabel,
	Checkbox,
	Typography,
} from "@mui/material";
import StatusIndicator from "../../components/UI/StatusIndicator";
import StatusIndicatorFullDate from "../../components/UI/StatusIndicatorFullDate";

function TableSchlusselVerlauf({
	data,
	selectedKeys,
	handleSelectKey,
	handleRequestSort,
	orderBy,
	order,
}) {
	// Сортировка данных по выбранному полю и порядку
	const sortedData = [...data].sort((a, b) => {
		if (orderBy === "status") {
			return order === "asc" ? a.status - b.status : b.status - a.status;
		} else if (orderBy === "key_address") {
			return order === "asc"
				? a.key_address.localeCompare(b.key_address)
				: b.key_address.localeCompare(a.key_address);
		} else if (orderBy === "taken_by") {
			return order === "asc"
				? (a.taken_by || "").localeCompare(b.taken_by || "")
				: (b.taken_by || "").localeCompare(a.taken_by || "");
		} else if (orderBy === "returned_by") {
			return order === "asc"
				? (a.returned_by || "").localeCompare(b.returned_by || "")
				: (b.returned_by || "").localeCompare(a.returned_by || "");
		} else {
			return order === "asc"
				? a[orderBy] - b[orderBy]
				: b[orderBy] - a[orderBy];
		}
	});

	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell padding="checkbox">
							<Checkbox
								indeterminate={
									selectedKeys.length > 0 &&
									selectedKeys.length < sortedData.length
								}
								checked={
									sortedData.length > 0 &&
									selectedKeys.length === sortedData.length
								}
								onChange={e =>
									handleSelectKey(
										e.target.checked ? sortedData.map(row => row.id) : []
									)
								}
							/>
						</TableCell>
						<TableCell>
							<TableSortLabel
								active={orderBy === "key_address"}
								direction={orderBy === "key_address" ? order : "asc"}
								onClick={() => handleRequestSort("key_address")}
							>
								Schlüssel
							</TableSortLabel>
						</TableCell>
						<TableCell>
							<TableSortLabel
								active={orderBy === "status"}
								direction={orderBy === "status" ? order : "asc"}
								onClick={() => handleRequestSort("status")}
							>
								Status
							</TableSortLabel>
						</TableCell>
						<TableCell>
							<TableSortLabel
								active={orderBy === "taken_by"}
								direction={orderBy === "taken_by" ? order : "asc"}
								onClick={() => handleRequestSort("taken_by")}
							>
								Genommen von
							</TableSortLabel>
						</TableCell>{" "}
						{/* Человек, который взял ключ */}
						<TableCell>
							<TableSortLabel
								active={orderBy === "returned_by"}
								direction={orderBy === "returned_by" ? order : "asc"}
								onClick={() => handleRequestSort("returned_by")}
							>
								Abgegeben von
							</TableSortLabel>
						</TableCell>{" "}
						{/* Человек, который вернул ключ */}
						<TableCell>Genommen am</TableCell>{" "}
						{/* Время, когда ключ был взят */}
						<TableCell>Abgegeben am</TableCell>{" "}
						{/* Время, когда ключ был возвращен */}
					</TableRow>
				</TableHead>
				<TableBody>
					{sortedData.length === 0 ? (
						<TableRow>
							<TableCell colSpan={7} style={{ textAlign: "center" }}>
								<Typography variant="h5">
									Zurzeit gibt es keine Schlüssel im Einsatz.
								</Typography>
							</TableCell>
						</TableRow>
					) : (
						sortedData.map(row => (
							<TableRow key={row.id} >
								<TableCell padding="checkbox">
									<Checkbox
										checked={selectedKeys.includes(row.id)}
										onChange={() => handleSelectKey(row.id)}
									/>
								</TableCell>
								<TableCell>{row.key_address}</TableCell>
								<TableCell>
									<StatusIndicatorFullDate
										status={row.status}
										takenDate={row.taken_date}
										returnedDate={row.returned_date}
									/>
								</TableCell>
								<TableCell>
									{/* Человек, который взял ключ */}
									{row.taken_by ? (
										<Typography component="span" style={{ fontWeight: "bold" }}>
											{row.taken_by}
										</Typography>
									) : (
										"-"
									)}
								</TableCell>
								<TableCell>
									{/* Человек, который вернул ключ */}
									{row.returned_by ? (
										<Typography component="span" style={{ fontWeight: "bold" }}>
											{row.returned_by}
										</Typography>
									) : (
										"-"
									)}
								</TableCell>
								<TableCell>
									{/* Время, когда ключ был взят */}
									{row.taken_date ? (
										<Typography component="span">
											{new Date(row.taken_date).toLocaleString()}
										</Typography>
									) : (
										"-"
									)}
								</TableCell>
								<TableCell>
									{/* Время, когда ключ был возвращен */}
									{row.returned_date ? (
										<Typography component="span">
											{new Date(row.returned_date).toLocaleString()}
										</Typography>
									) : (
										"-"
									)}
								</TableCell>
							</TableRow>
						))
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default TableSchlusselVerlauf;
