import React, { useState } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TableSortLabel,
	Typography,
	CircularProgress,
	TextField, // Добавляем TextField для поля поиска
	IconButton, // Для кнопки перезагрузки
	Modal,
	Box,
	Button,
	Snackbar,
	Alert,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh"; // Иконка перезагрузки
import useGetKeys from "../../api/keysManagment/useGetKeys";
import StatusIndicator from "../../components/UI/StatusIndicator";
import CloseIcon from "@mui/icons-material/Close";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import DeleteIcon from "@mui/icons-material/Delete";

import { QRCodeSVG } from "qrcode.react";
import EditKeyModal from "./EditKeyModal";
import useUpdateKey from "../../api/keysManagment/useUpdateKey";
import useDeleteKey from "../../api/keysManagment/useDeleteKey";

const TableSchlussel = () => {
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("key_address");
	const [searchQuery, setSearchQuery] = useState(""); // Для поиска по ключу
	const [searchPerson, setSearchPerson] = useState(""); // Для поиска по имени человека

	const [modalOpen, setModalOpen] = useState(false);
	const [selectedKey, setSelectedKey] = useState(null); // Выбранный ключ

	const [openModalEdit, setOpenModalEdit] = useState(false);

	const [data, setData, error, loading, refetch] = useGetKeys();

	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("success");

	// Стейты для подтверждения удаления
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [keyToDelete, setKeyToDelete] = useState(null);


	const [updateKey, isLoaded, errorUpdate] = useUpdateKey();
	const [deleteKey] = useDeleteKey(); // Хук для удаления


	// Открытие модального окна с данными выбранного ключа
	const handleOpenModalEdit = key => {
		setSelectedKey(key);
		setOpenModalEdit(true);
	};

	// Закрытие модального окна
	const handleCloseModalEdit = () => {
		setOpenModalEdit(false);
		setSelectedKey(null);
	};

	const handleSaveKey = async updatedKey => {
		try {
			await updateKey(updatedKey);

			// Обновляем локальные данные
			setData(prevKeys =>
				prevKeys.map(key =>
					key.id === updatedKey.id ? { ...key, ...updatedKey } : key
				)
			);

			// Уведомление об успехе
			setSnackbarMessage("Schlüssel wurde erfolgreich aktualisiert.");
			setSnackbarSeverity("success");
			setSnackbarOpen(true);
		} catch (err) {
			// Уведомление об ошибке
			setSnackbarMessage(errorUpdate || "Fehler beim Aktualisieren des Schlüssels.");
			setSnackbarSeverity("error");
			setSnackbarOpen(true);
		}
	};

	const handleCloseSnackbar = () => {
		setSnackbarOpen(false);
	};

	const handleDeleteKey = async () => {
		if (keyToDelete) {
			try {
				await deleteKey(keyToDelete);
				setData((prevData) => prevData.filter((item) => item.id !== keyToDelete)); // Удаление из локальных данных
				setSnackbarMessage("Schlüssel wurde erfolgreich gelöscht.");
				setSnackbarSeverity("success");
				setSnackbarOpen(true);
			} catch (err) {
				setSnackbarMessage("Fehler beim Löschen des Schlüssels.");
				setSnackbarSeverity("error");
				setSnackbarOpen(true);
			}
			setOpenDeleteDialog(false); // Закрытие окна подтверждения
		}
	}
	const openDeleteConfirmation = (id) => {
		setKeyToDelete(id); // Запоминаем ключ, который нужно удалить
		setOpenDeleteDialog(true); // Открытие диалога
	};

	const handleCloseDeleteDialog = () => {
		setOpenDeleteDialog(false); // Закрытие окна подтверждения
		setKeyToDelete(null); // Сброс состояния
	};


	// Используем наш хук для получения данных

	const handleRequestSort = property => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	// Фильтруем данные на основе строки поиска
	const filteredData = data
		? data.filter(
			row =>
				// Фильтрация по ключу
				row.key_address.toLowerCase().includes(searchQuery.toLowerCase()) &&
				// Фильтрация по имени владельца, если оно задано
				(searchPerson === "" ||
					row.owner?.toLowerCase().includes(searchPerson.toLowerCase()) ||
					row.previous_owner
						?.toLowerCase()
						.includes(searchPerson.toLowerCase()))
		)
		: [];

	// Обработка сортировки данных
	const sortedData = [...filteredData].sort((a, b) => {
		if (orderBy === "status") {
			return order === "asc" ? a.status - b.status : b.status - a.status;
		} else if (orderBy === "key_address") {
			return order === "asc"
				? a.key_address.localeCompare(b.key_address)
				: b.key_address.localeCompare(a.key_address);
		} else {
			return order === "asc"
				? a[orderBy] - b[orderBy]
				: b[orderBy] - a[orderBy];
		}
	});

	// Открыть модальное окно с выбранным ключом
	const handleOpenModal = key => {
		setSelectedKey(key);
		setModalOpen(true);
	};

	// Закрыть модальное окно
	const handleCloseModal = () => {
		setModalOpen(false);
		setSelectedKey(null);
	};

	// Скачивание QR-кода
	const downloadQRCode = (text, fileName) => {
		const svgElement = document.getElementById(`${fileName}`); // ID SVG элемента
		if (svgElement) {
			const serializer = new XMLSerializer();
			const svgString = serializer.serializeToString(svgElement); // Преобразуем в строку
			const base64 = `data:image/svg+xml;base64,${btoa(
				unescape(encodeURIComponent(svgString))
			)}`; // Преобразуем в base64
			const link = document.createElement("a");
			link.href = base64;

			// Добавляем суффикс -nehmen или -geben в зависимости от значения fileName
			const suffix = fileName === "qr-get" ? "-nehmen" : "-geben";
			link.download = `${text}${suffix}.svg`; // Имя файла с суффиксом
			link.click(); // Запуск скачивания
		}
	};

	// Отображение загрузки или ошибки
	if (loading) {
		return (
			<Paper style={{ padding: "100px", textAlign: "center" }}>
				<CircularProgress color="secondary" />
				<Typography variant="h6" paddingTop="32px">
					Daten laden...
				</Typography>
			</Paper>
		);
	}

	if (error) {
		return (
			<Paper
				style={{
					padding: "100px",
					textAlign: "center",
					color: "#C70039",
				}}
			>
				<Typography variant="h3">Ein Fehler ist aufgetreten:</Typography>
				<Typography variant="h4" paddingTop="32px">
					{error}
				</Typography>
			</Paper>
		);
	}

	return (
		<>
			{/* Поля для поиска */}
			<div
				style={{
					display: "flex",
					gap: "30px",
					marginBottom: "20px",
					alignItems: "center",
				}}
			>
				{/* Поиск по ключу */}
				<TextField
					label="Suche nach Schlüssel"
					variant="outlined"
					sx={{ width: "400px", marginTop: 0 }}
					margin="normal"
					value={searchQuery}
					onChange={e => setSearchQuery(e.target.value)}
				/>

				{/* Поиск по имени человека */}
				<TextField
					label="Suche nach Person (Besitzer)"
					variant="outlined"
					sx={{ width: "400px", marginTop: 0 }}
					margin="normal"
					value={searchPerson}
					onChange={e => setSearchPerson(e.target.value)}
				/>
				{/* Кнопка перезагрузки данных */}
				<IconButton onClick={refetch} aria-label="Refresh" size="large">
					<RefreshIcon fontSize="inherit" />
				</IconButton>
			</div>

			<TableContainer component={Paper}>
				<Table sx={{ width: '100%' }}>
					<TableHead>
						<TableRow>
							<TableCell sx={{ width: '5%' }}>
								<TableSortLabel
									active={orderBy === "id"}
									direction={orderBy === "id" ? order : "asc"}
									onClick={() => handleRequestSort("id")}
								>
									ID
								</TableSortLabel>
							</TableCell>
							<TableCell sx={{ width: '30%' }}>
								<TableSortLabel
									active={orderBy === "key_address"}
									direction={orderBy === "key_address" ? order : "asc"}
									onClick={() => handleRequestSort("key_address")}
								>
									Schlüssel
								</TableSortLabel>
							</TableCell>
							<TableCell sx={{ width: '15%' }}>
								<TableSortLabel
									active={orderBy === "status"}
									direction={orderBy === "status" ? order : "asc"}
									onClick={() => handleRequestSort("status")}
								>
									Status
								</TableSortLabel>
							</TableCell>
							<TableCell sx={{ width: '30%' }}>Persone</TableCell>
							<TableCell sx={{ width: '20%' }}>Aktionen</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{sortedData.length === 0 ? (
							<TableRow>
								<TableCell colSpan={5} style={{ textAlign: "center", width: "100%" }}>
									<Typography variant="h5">
										Zurzeit gibt es keine Schlüssel im Einsatz.
									</Typography>
								</TableCell>
							</TableRow>
						) : (
							sortedData.map((row, index) => (
								<TableRow
									key={row.id}
									style={{
										cursor: "pointer",
										transition: "background-color 0.3s ease",
									}}
									onClick={() => handleOpenModalEdit(row)}
									sx={{
										"&:hover": {
											backgroundColor: "rgba(0, 151, 255, 0.15)",
										},
									}}
								>
									<TableCell sx={{ width: '5%' }}>{row.id}</TableCell>
									<TableCell sx={{ width: '30%' }}>{row.key_address}</TableCell>
									<TableCell sx={{ width: '15%' }}>
										<StatusIndicator
											status={row.status}
											takenDate={row.taken_date}
											returnedDate={row.returned_date}
										/>
									</TableCell>
									<TableCell sx={{ width: '30%' }}>
										{row.status === 0 ? (
											<>
												<Typography component="span">Genommen von </Typography>
												<Typography component="span" style={{ fontWeight: "bold" }}>
													{row.owner}
												</Typography>
											</>
										) : row.status === 1 ? (
											<>
												<Typography component="span">Abgegeben von </Typography>
												<Typography component="span" style={{ fontWeight: "bold" }}>
													{row.previous_owner}
												</Typography>
											</>
										) : (
											"-"
										)}
									</TableCell>
									<TableCell sx={{ width: '20%' }}>
										<IconButton
											color="primary"
											onClick={e => {
												e.stopPropagation();
												handleOpenModal(row);
											}}
											aria-label="QR-Code"
											sx={{
												color: "white",
												fontSize: "1.3rem", // Увеличение размера иконки
											}}
										>
											<QrCode2Icon fontSize="large" />
										</IconButton>
										<IconButton
											color="error"
											onClick={(e) => {
												e.stopPropagation();
												openDeleteConfirmation(row.id);
											}}
										>
											<DeleteIcon />
										</IconButton>
									</TableCell>
								</TableRow>
							))
						)}
					</TableBody>
				</Table>
			</TableContainer>



			<Dialog
				open={openDeleteDialog}
				onClose={handleCloseDeleteDialog}
				aria-labelledby="delete-dialog-title"
			>
				<DialogTitle id="delete-dialog-title">Schlüssel löschen</DialogTitle>
				<DialogContent>
					<Typography variant="body1">
						Möchten Sie diesen Schlüssel wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.
					</Typography>
				</DialogContent>
				<DialogActions>


					<Button onClick={handleCloseDeleteDialog} variant="primary">
						Abbrechen
					</Button>
					<Button
						onClick={handleDeleteKey}
						color="error"
						startIcon={<DeleteIcon />}
					>
						Löschen
					</Button>
				</DialogActions>
			</Dialog>


			<EditKeyModal
				open={openModalEdit}
				keyData={selectedKey}
				onClose={handleCloseModalEdit}
				onSave={handleSaveKey}
			/>

			<Modal open={modalOpen} onClose={handleCloseModal}>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: 600,
						bgcolor: "background.paper",
						boxShadow: 24,
						p: 4,
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						gap: 3,
						borderRadius: "8px",
					}}
				>
					{/* Крестик для закрытия */}

					<IconButton
						aria-label="close"
						onClick={handleCloseModal}
						sx={{
							position: "absolute",
							right: 15,
							top: 15,
						}}
					>
						<CloseIcon />
					</IconButton>

					{selectedKey && (
						<>
							<Typography variant="h6" sx={{ marginBottom: 2 }}>
								QR-Codes für den Schlüssel:{" "}
								<Typography component="span" sx={{ fontWeight: "bold" }}>
									{selectedKey.key_address}
								</Typography>
							</Typography>

							{/* QR-коды и кнопки */}
							<Box
								sx={{
									display: "flex",
									justifyContent: "center",
									gap: 4,
								}}
							>
								{/* QR-код для взятия */}
								<Box sx={{ textAlign: "center" }}>
									<QRCodeSVG
										id="qr-get"
										value={`get-${selectedKey.id}`}
										size={200}
										fgColor="#E57373" // Мягкий красный
										bgColor="#FFFFFF"
										level="L"
										includeMargin={true}
									/>
									<Typography sx={{ marginTop: 1, marginBottom: 3 }}>
										Schlüssel nehmen
									</Typography>
									<Button
										variant="contained"
										color="error"
										onClick={() =>
											downloadQRCode(selectedKey.key_address, "qr-get")
										}
									>
										Herunterladen
									</Button>
								</Box>

								{/* QR-код для возврата */}
								<Box sx={{ textAlign: "center" }}>
									<QRCodeSVG
										id="qr-return"
										value={`return-${selectedKey.id}`}
										size={200}
										fgColor="#81C784" // Зеленый
										bgColor="#FFFFFF"
										level="L"
										includeMargin={true}
									/>
									<Typography sx={{ marginTop: 1, marginBottom: 3 }}>
										Schlüssel zurückgeben
									</Typography>
									<Button
										variant="contained"
										color="success"
										onClick={() =>
											downloadQRCode(selectedKey.key_address, "qr-return")
										}
									>
										Herunterladen
									</Button>
								</Box>
							</Box>
						</>
					)}
				</Box>
			</Modal>

			<Snackbar
				open={snackbarOpen}
				autoHideDuration={3000} // Скрыть через 3 секунды
				onClose={handleCloseSnackbar}
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
			>
				<Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</>
	);
};

export default TableSchlussel;
