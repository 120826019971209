const convertTeam = str => {
	// Проверяем, что строка имеет формат "TeamN", где N - число
	const match = str.match(/^Team(\d+)$/);

	if (match) {
		// Извлекаем число из строки
		const teamNumber = match[1];

		// Формируем новую строку в формате "Tour N"
		const tourString = `Tour ${teamNumber}.`;

		return tourString;
	} else {
		// Если формат строки некорректный, возвращаем исходную строку
		return str;
	}
};

export default convertTeam;
