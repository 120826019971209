import React, { useContext, useEffect, useState } from "react";
import {
	Button,
	CircularProgress,
	TextField,
	Typography,
	Paper,
	IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from "@mui/icons-material/Refresh"; // Импортируем иконку перезагрузки
import DialogModal from ".//DialogModal";
import useGetKeysHistory from "../../api/keysManagment/useGetKeysHistory";
import { PageTitleContext } from "../../PageTitleContext";
import TableSchlusselVerlauf from "./TableSchlusselVerlauf";

function SchlusselVerlauf() {
	const { setTitle } = useContext(PageTitleContext);

	useEffect(() => {
		setTitle("Schlüssel Verlauf");
	}, [setTitle]);

	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("key_address");
	const [searchQuery, setSearchQuery] = useState(""); // Для поиска по ключу
	const [searchPerson, setSearchPerson] = useState(""); // Для поиска по имени человека
	const [selectedKeys, setSelectedKeys] = useState([]);
	const [isDialogOpen, setDialogOpen] = useState(false);

	// Используем хук для получения данных ключей
	const [data, setData, error, loading, refetch] = useGetKeysHistory(); // Добавляем refetch

	const handleRequestSort = property => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	// Фильтруем данные по всем полям поиска
	const filteredData = data
		? data.filter(
				row =>
					// Фильтрация по ключу, и по имени человека, который взял или вернул ключ
					row.key_address.toLowerCase().includes(searchQuery.toLowerCase()) &&
					(row.taken_by?.toLowerCase().includes(searchPerson.toLowerCase()) ||
						row.returned_by?.toLowerCase().includes(searchPerson.toLowerCase()))
		  )
		: [];

	const handleSelectKey = key => {
		setSelectedKeys(prevSelected =>
			prevSelected.includes(key)
				? prevSelected.filter(id => id !== key)
				: [...prevSelected, key]
		);
	};

	const handleDeleteSelected = () => {
		setDialogOpen(true);
	};

	const handleConfirmDelete = () => {
		setData(prevData => prevData.filter(row => !selectedKeys.includes(row.id)));
		setSelectedKeys([]); // Очищаем выделение после удаления
		setDialogOpen(false);
	};

	// Функция для перезагрузки данных
	const handleReloadData = () => {
		refetch(); // Повторно вызываем запрос для получения новых данных
	};

	// Проверка загрузки или ошибки
	if (loading) {
		return (
			<Paper style={{ padding: "100px", textAlign: "center" }}>
				<CircularProgress color="secondary" />
				<Typography variant="h6" paddingTop="32px">
					Daten laden...
				</Typography>
			</Paper>
		);
	}

	if (error) {
		return (
			<Paper
				style={{ padding: "100px", textAlign: "center", color: "#C70039" }}
			>
				<Typography variant="h3">Ein Fehler ist aufgetreten:</Typography>
				<Typography variant="h4" paddingTop="32px">
					{error}
				</Typography>
			</Paper>
		);
	}

	return (
		<div className="deleteObjekt">
			<div className="deleteObjekt__content">
				<Typography
					variant="h3"
					sx={{ marginTop: "30px", marginBottom: "50px" }}
				>
					Hier können Sie Schlüssel-Verlauf anschauen.
				</Typography>

				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						marginBottom: "20px",
					}}
				>
					<div>
						<TextField
							label="Suche nach Schlüssel Adresse"
							variant="outlined"
							fullWidth
							margin="normal"
							value={searchQuery}
							onChange={e => setSearchQuery(e.target.value)}
							style={{
								marginTop: 0,
								width: "400px",
								marginRight: "20px",
								marginBottom: "10px",
							}}
						/>
						<TextField
							label="Suche nach Person (genommen oder zurückgegeben)"
							variant="outlined"
							fullWidth
							margin="normal"
							value={searchPerson}
							onChange={e => setSearchPerson(e.target.value)}
							style={{
								marginTop: 0,
								width: "400px",
								marginRight: "20px",
								marginBottom: "10px",
							}}
						/>
						<IconButton
							onClick={handleReloadData}
							aria-label="Refresh"
							size="large"
						>
							<RefreshIcon fontSize="inherit" />
						</IconButton>
					</div>

					<Button
						variant="contained"
						color="error"
						onClick={handleDeleteSelected}
						disabled={selectedKeys.length === 0}
						startIcon={<DeleteIcon />}
					>
						Löschen: {selectedKeys.length}
					</Button>
				</div>

				{/* Передаем данные в компонент таблицы */}
				<TableSchlusselVerlauf
					data={filteredData}
					selectedKeys={selectedKeys}
					handleSelectKey={handleSelectKey}
					handleRequestSort={handleRequestSort}
					orderBy={orderBy}
					order={order}
				/>

				{/* Модальное окно подтверждения удаления */}
				<DialogModal
					open={isDialogOpen}
					handleClose={() => setDialogOpen(false)}
					loading={loading}
					activeObject={data.filter(item => selectedKeys.includes(item.id))}
					handleConfirmDelete={handleConfirmDelete}
				/>
			</div>
		</div>
	);
}

export default SchlusselVerlauf;
