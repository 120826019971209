import { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../config";
import { useAuth } from "../AuthContext";

const useGetAllAddressesAPI = week => {
	const [data, setData] = useState([]);
	const [isLoaded, setIsLoaded] = useState(false);
	const [error, setError] = useState(null);
	const { logout } = useAuth();

	useEffect(() => {
		const fetchData = async () => {
			setIsLoaded(false);
			setError(null);
			try {
				const token = localStorage.getItem("token"); // Извлечение токена из локального хранилища

				// Обращаемся к API для получения данных
				const response = await axios.get(
					`${API_URL}/get_weekly_unassignments.php`,
					{
						params: {
							week: week,
						},
						headers: {
							Authorization: `Bearer ${token}`, // Добавление токена в заголовок
						},
					}
				);
				const data = response.data;

				if (response.status === 401) {
					logout();
				}

				if (response.status === 200 && data.length) {
					setData(JSON.parse(data[0].unassignments));
				} else {
					setError("Für diese Woche wurden keine Objekte gefunden");
				}
			} catch (err) {
				if (error?.response?.status === 401) {
					logout();
				}
				setError("Etwas ist schief gelaufen");
			} finally {
				setIsLoaded(true);
			}
		};

		if (week) {
			fetchData();
		}
	}, [week]); // Зависимость от номера недели

	return { data, isLoaded, error };
};

export default useGetAllAddressesAPI;
