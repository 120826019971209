import { useContext, useEffect } from "react";
import { PageTitleContext } from "../../PageTitleContext";
import { TextField, Typography } from "@mui/material";
import Form from "./Form";

const AddObjekt = () => {
	// Изменить название заголовка страницы
	const { setTitle } = useContext(PageTitleContext);
	useEffect(() => {
		setTitle("Neues Objekt hinzufügen");
	}, [setTitle]);

	return (
		<div className="addObjekt">
			<div className="addObjekt__content">
				<Typography variant="h3" sx={{ marginTop: "30px" }}>
					Hier können Sie die neue Objekte hinzufügen.
				</Typography>
				<div className="addObjekt__body">
					<Form />
				</div>
			</div>
		</div>
	);
};

export default AddObjekt;
