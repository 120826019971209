import { useCallback } from "react";
import axios from "axios";
import { API_URL } from "../../config";
import { useAuth } from "../../AuthContext";

const useDeleteObjektsAPI = () => {
	const { logout } = useAuth(); // Используем хук для выхода из системы

	const deleteObjekts = useCallback(
		async ids => {
			try {
				// Получаем токен из локального хранилища
				const token = localStorage.getItem("token");

				// Отправляем запрос на сервер для удаления объектов
				const response = await axios.post(
					`${API_URL}/keys_management/delete_objekts.php`,
					{ ids }, // Список ID объектов для удаления
					{
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`, // Добавляем токен в заголовки
						},
					}
				);

				// Возвращаем ответ от сервера (например, успешное удаление)
				return response.data;
			} catch (error) {
				// Обработка ошибки, если запрос не удастся
				if (error.response && error.response.status === 401) {
					// Если ошибка авторизации, вызываем функцию logout
					logout();
					return false; // Возвращаем false в случае ошибки авторизации
				}

				// Пробрасываем ошибку дальше, если это не ошибка авторизации
				throw error;
			}
		},
		[logout] // Используем logout, если токен истек
	);

	return deleteObjekts; // Возвращаем функцию для удаления объектов
};

export default useDeleteObjektsAPI;
