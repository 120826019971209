// src/PageTitleContext.js
import React, { createContext, useState } from "react";
import { useAuth } from "./AuthContext";

export const PageTitleContext = createContext();

export const PageTitleProvider = ({ children }) => {
	const [title, setTitle] = useState("Verplanung der Reinigung");

	return (
		<PageTitleContext.Provider value={{ title, setTitle }}>
			{children}
		</PageTitleContext.Provider>
	);
};
